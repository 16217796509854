import "../../core/src/modules/doc-info/views/database-properties/doc-database-backlink-info.css.ts.vanilla.css!=!../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/modules/doc-info/views/database-properties/doc-database-backlink-info.css.ts.vanilla.css\",\"source\":\"Ll8xMTZwenBwMCB7CiAgZGlzcGxheTogZmxleDsKICBmbGV4LWRpcmVjdGlvbjogY29sdW1uOwp9Ci5fMTE2cHpwcDEgewogIGRpc3BsYXk6IGZsZXg7CiAgZmxleC1kaXJlY3Rpb246IGNvbHVtbjsKICBnYXA6IDRweDsKfQouXzExNnB6cHAyIHsKICBkaXNwbGF5OiBmbGV4Owp9Ci5fMTE2cHpwcDMgewogIG1hcmdpbjogOHB4IDA7Cn0KLl8xMTZwenBwNCB7CiAgZmxleDogMTsKfQouXzExNnB6cHA1IHsKICBtYXgtd2lkdGg6IDUwJTsKICBmb250LXNpemU6IHZhcigtLWFmZmluZS1mb250LXNtKTsKICBvdmVyZmxvdzogaGlkZGVuOwogIHRleHQtb3ZlcmZsb3c6IGVsbGlwc2lzOwogIHdoaXRlLXNwYWNlOiBub3dyYXA7CiAgY29sb3I6IHZhcigtLWFmZmluZS12Mi10ZXh0LXRlcnRpYXJ5KTsKfQouXzExNnB6cHA2IHsKICBtYXgtd2lkdGg6IDExMHB4OwogIG1pbi13aWR0aDogNjBweDsKfQouXzExNnB6cHA3IHsKICBwYWRkaW5nOiAwIDJweDsKICBkaXNwbGF5OiBmbGV4OwogIGZsZXgtZGlyZWN0aW9uOiBjb2x1bW47CiAgZ2FwOiA0cHg7Cn0KLl8xMTZwenBwOCB7CiAgZGlzcGxheTogZmxleDsKICBhbGlnbi1pdGVtczogY2VudGVyOwogIGdhcDogNHB4OwogIG92ZXJmbG93OiBoaWRkZW47Cn0KLl8xMTZwenBwOSB7CiAgdGV4dC1vdmVyZmxvdzogZWxsaXBzaXM7CiAgd2hpdGUtc3BhY2U6IG5vd3JhcDsKICBvdmVyZmxvdzogaGlkZGVuOwogIGRpc3BsYXk6IGlubGluZS1ibG9jazsKfQ==\"}!../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var cell = '_116pzpp2';
export var cellList = '_116pzpp7';
export var databaseName = '_116pzpp9';
export var databaseNameWrapper = '_116pzpp8';
export var divider = '_116pzpp3';
export var docRefLink = '_116pzpp5';
export var mobileDocRefLink = '_116pzpp6 _116pzpp5';
export var root = '_116pzpp0';
export var section = '_116pzpp1';
export var spacer = '_116pzpp4';