import "../../core/src/components/page-list/docs/page-list-item.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/page-list/docs/page-list-item.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA71W227bMAx9z1cIBQo0wBQ412Uqtv8YhmFQJNrmKkuGJDfphv77YMmO7cRJ167Ym02L5OHhEenZj3Uqodok5PeEEImuVPyJkVTB4X5CiDDKWEYeub2jlKcpaqAeDp6WFgtun2g4MK2P5oBZ7hlZr8rgWoegLreoHxhJassepc8ZmSfJbf3KFWaaoofCMeK8BS/ymFN7jjrYUPja5C3XDj0azciOi4fMmkrLmJwks4X7QEzJBfqn8Fa7mEewqTJ7RnKUEnSIXFlXlyMh5ZXy95Pnyaytn+W1R2DhNMNJ/eHgsfBeiG+Se06FQvHAdwo+e1vB9xCxTVwa1B5s32seDrTkrdaRvJ2xEiy1XGLlGNlG61l7BhQKiLH7BVyGTlNUCuQ0Zqs7xWXN1uB8AbpqvoSDBT/QpovLTRJBFahb2yLauuIWobjStL2zoLjHR4ixbIaaKkg9I7SpsOWh1YipvEINjGijg1fJpUSdNW7bmC4SLy3Psh7xRxBsB6mxEDthtAftGbm5uR9A4ztnVOVDEm9KRtYRQZBeamzB4qPiHr7e0XVyGxiJOPryXg0raV77Co6fLuj2BfGVigvIjZI9CZ7JZRFzNqEDvJdJCtroU3XsxWm4+cVwPSPq7DRFiNqBmq3fGGcM5LIB2SpxDPLxopIvhKVonaciRyWjSAfCmm+GQg4uio962IhgO/RYjg/UCzf2Z+U8pvU4beTZfcp4Wd+HYfTVq6Kfj+LUaE8d/oITeQV7TpeDwbYeTxaiSrQgoqyFUVWhz2DE5J5b37u/jCQNx4OhIrgSd/XdJ5RsVuVh2uYJHSRkn6MH6kouwkjYW17W5sqBpQ4UCN9Oig79JspuZB1cJcEV0+OZfSOyTZJcQdHDGVZklxKUwtKh67hxoNLe0uvQfryI9uIqdiCMlsNlfLW0g/tLXt9exvZ1+nxZjeP1ozC6X/WJzjs8n94PT8Peq9rRU/3Jomu36ODfqMPN/x/u8z+6kbFRW2p19FVyXtzV+7j7t8EYIICW1/st3i9JHGs0jOHN+K/t8x9c9mjQRgsAAA==\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var actionsCellWrapper = '_5fdeu6b';
export var dateCell = '_5fdeu6a';
export var dndCell = '_5fdeu62';
export var dragPageItemOverlay = '_5fdeu61';
export var iconCell = '_5fdeu68';
export var operationsCell = '_5fdeu6c';
export var root = '_5fdeu60';
export var selectionCell = '_5fdeu64';
export var tagsCell = '_5fdeu69';
export var titleCell = '_5fdeu65';
export var titleCellMain = '_5fdeu66';
export var titleCellPreview = '_5fdeu67';
export var titleIconsWrapper = '_5fdeu63';