import "../../core/src/components/page-list/docs/page-list-header.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/page-list/docs/page-list-header.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA7VWy46jMBC85yt8WWnn4BGQx2acj1k50EDPGBvZJjC72n9f2RBwEvIaaS6RYuzq7urqsl9/x81at4dlRP4uCCkBi9IyEkdR3e0WhHCBhaRooTKMpCAtaLdc8yxDWTCy2tYdiTd1R5LI/az6Y+oAOheqZaTELAPp1jI0teCfjOQC/Kb3xljMP2mqpAVpGTE1T4HuwbbQH9nz9KPQqpEZIweuf1LK8xwl0OkDrTVWXDsUofTLbvFv8XqsKfY15UpaavAPnGH49ZKuX3bHTe1Q/TqK3JpHPDtkobPUQKpkFgadqe4KcwWvGdn2LB3ZTob/jQFNDQhILSNSSTipJvHVzCWFqZJzqaAUvtBbGQUBllcD+KpvEL3yJx+l4H7fR3nFdXck6+G87nS8M37PXukMNNU8w8Y4wY9RmkpS36VBy/3OPhejBGZnUuyBpuhfES0hFe9oi5ktGVlGh9Yt+eqmSQIhsDZo3Ke2RAvU8+aU0mpenwgq6VP37R9n+nXzCypfZKON47JWeCGCtW/lkMmZTrchISN16+iHJ11AR02pUX4wEp1gbjzmnCc8W2IA+uvRcQjUEHteApDtF0CS6AzkbV79npAMNaQWlWSDtHYTu3HUMzeqfXsGzD3w+DkKziZvm2tOGwDs5zMLPchn6cgNdTSGdMYeD2KqUI5aWiU3rocggbR3YBej0C7J+HS83Z0R9fLXBUpG6LR0nLveCAlRjXV6nhZujrmppr7lvELh/bAEjTZwk2AlmM7ho9VcmpprkPYu0yljteAplEpkoK+qKtgz56PZs0pyrjH25HhnT4nyxqqTAHAyibQLZ7FWBnt8DYJbPMB5hOQYYRJQgJ0/lrxW7UMPi+FdEfrPMjkP/5gILvzuwsNWT94xYdmsdHz64i81dALlN85jHNCghezLN3D+mqHhewE3s2mkARsyciGQ4hvsv/wO+8cb1hbfcqeZ98eMcb3ffzoOD4mnXo4XHfwP152a4YALAAA=\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var arrowDownSmallIcon = '_1u5rwv37';
export var buttonText = '_1u5rwv3j';
export var docListHeader = '_1u5rwv30';
export var docListHeaderTitle = '_1u5rwv31';
export var rightButtonGroup = '_1u5rwv3i';
export var searchIcon = '_1u5rwv38';
export var searchInput = '_1u5rwv3c';
export var tagIcon = '_1u5rwv3g';
export var tagIndicator = '_1u5rwv35';
export var tagLabel = '_1u5rwv36';
export var tagSelectorItem = '_1u5rwv3f';
export var tagSelectorItemText = '_1u5rwv3h';
export var tagSelectorTagsScrollContainer = '_1u5rwv3e';
export var tagSticky = '_1u5rwv34';
export var tagsEditorRoot = '_1u5rwv39';
export var tagsEditorSelectedTags = '_1u5rwv3b';
export var tagsEditorTagsSelector = '_1u5rwv3d';
export var tagsMenu = '_1u5rwv3a';
export var titleCollectionName = '_1u5rwv33';
export var titleIcon = '_1u5rwv32';