import "../../core/src/components/workspace-selector/workspace-card/styles.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/workspace-selector/workspace-card/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA7VWbW/bIBD+nl+BIk1qpBLhNOlaV/0h09QPGM4OCwYLsJN02n+fjB2HOMRbp+6bDdzbc3fP3dIW2Yo8EPRzhtAWRLF1KdqQ6vAyQ4gLW0l6TFEuwR9QKQqFhYPSpoiBcmDa44JWKXrqZCrKuVBFigh67E4ybTgYbCgXtU3RujvVtZNCQYqUVtAe7AV32xQlhHxpf0t6wP3RhvT+MC21SVFDzR3GNM+FAtyssIODw5URJTXHxcvs12zZB5VudQPGh8ZqY1vZSouT1xllu8LoWvGRSi+FvbFQXeIV9T6RVkMLS4qSf4ZKW+GEVikyIKkTDQTWVt5aqw3brRFq520O1w/+uo8GQwPK2TOWuqJMuGOKyPIpFIoAwiGntXRjQDpNg+T6oj7Wqz6HDZhc6n2KtoJzUIHAxgtcoeLj4cIA6wJnWtalusKrC9tR4/1yhiqba1Om3aekDr7dkcVw16M4vENkubGI1ZlgOIN3AeZuud7cL1fJPblPFojYMLTvnDqKKWsT8Dp3pob5G7oII+4AbmEIC+QxLJBTHftQCtOClMQxu8b1A7D9qK0T+REzrRwoF5ZaCE1fEJ8HzGN36Y4VvM6VNiWV8zcf/7n2buvKqbS3lHW1PKXsa9caWjlsxTuMurc7L311SN/OPbqrHl3/YD9Qne/k2oLBFiQwF3RRJFWea84XIKWorLCewLbCAbYVZZ7V9oZWEV4bciuU9y6Tmu2C4J7ijeN5ow/gBrVMAnKwEUBIBJB1B0iMajNJ2Q5vyOLCXdsUF3Xfs/7JyOk/plAwrbAFphUfUffz/0FhqixiDvpsDw5ej4TnT40+CHhcFfTPJT+V4WAo97OnFGr0arCVfZS6JzgomJATw4Ld7vXTDI+/TIKXvBtr8RUhgnUAZrK+AADOpN/TJ5USkWXyYBFQG24rJEz2Ce0Yb5TUFEJhCbnrpf52Y5hAt5KUwbXEFAbnNenWYpbpduOgvPX/xIThZuBxqagB5Yfz1WJ1K3UQ9skJqtDYJdfUzrUp83eLyXWtWQ2vY1SS95vSkMvMalk7H5fpEhfPR7iqRXNzK9Kxxch2V4z7OVlPrrg+d356Uge8De83hkXW97kLAAA=\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var activeContainer = 'sgb2032';
export var activeIcon = 'sgb203g';
export var collaborationIcon = 'sgb203d';
export var container = 'sgb2030';
export var disable = 'sgb2033';
export var enableCloudButton = 'sgb203c';
export var infoContainer = 'sgb2031';
export var settingButton = 'sgb203e';
export var showOnCardHover = 'sgb203f';
export var workspaceActiveStatus = 'sgb2039';
export var workspaceInfo = 'sgb2036';
export var workspaceInfoSlide = 'sgb2035';
export var workspaceInfoSlider = 'sgb2034';
export var workspaceInfoTooltip = 'sgb203a';
export var workspaceName = 'sgb2037';
export var workspaceStatus = 'sgb2038';
export var workspaceTitleContainer = 'sgb203b';