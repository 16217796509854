import "../../core/src/components/tags/tag.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/tags/tag.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA61W227bMAx9z1fopUANVIHjpFujYF8y7EGxaJutIhmSnMuK/fsgyXFsx0kRbC+BTZnk4TkklXkBBt8hI58zQmpt0aFWjBiQ3OEeNjNCDihcxcgiTZ/8awVYVu7yvkNFz7ZlVh83sz+zeYy6DFEF2lryEyOFhKP34BJLRdHBzjKSg3Jgek6r4KT3YAqpD/TISIVCgNrcBDhGVPKakbcBktcQ1AOgB+OP/e/mbBJoII9xcy2bnbpCGT6zjhvXJVgNEnybLjWGoxdEN6rvl8a3VsvGTZa240c60sMZrjpXKUk6zyxJ50tLgFugqKhuXA9pxipPLhkA78Xdc/NMaTxMk57j91GP9HE6XTOS+icJhWsfTcSeThWia56jO7WnUzV4+5bnH6XRjRKMSFTADS0NFwjKPa9TAeVLdK25AeVI+vTSwudFgQpoqJTmWmpDC5QSREJW6VNym45YZIdu0fvyLRydC8myKOeV5H4eWirTO3q/N9ZhcaK5Vg6UY8TWPAe6BXcA3+yXtExy62heoRRRq0sCL1sboOexfmDsvgZCSM2FQFUykp57ODDKhlw7ODpaG9xxc4qUJ0FCbcRZg5FH/6gvyfqn4I7TQueNBfHDmQZ+hZLuxBon7oLxOPlaOWrxNzCCqgKDrofNd1RjfW/WV0O7iqb2y/PoDYzWnSQwYrVEMW7aYb3dwQRP/cnOWprHQxMoPm9GRkBKrC2GSTlU6IAG6RhROq63joTtHRI6dVf1kSyyawpaMI8mv8BfpoNVmY/BDEiK9l0yzWSMsEim23Kk50C7oFoUaZhwn1Hfblu/LOOnyY0dn//PvhRfs1DR12S8Ry/r1zrMP06DFYRKBucw8F0mCJna3hp11jRxr7Hd4qVXGVQfbfZ7gnT5isEF3r++H2+hxvoBAwm582YFvTzlv625y1E3dqOpyx4jJ2+M9frXGkd/acr2jvm8tx16d5Vn8y+t3AlYmQkAAA==\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var hoverMaxWidth = 'var(--ferije0)';
export var innerBackdrop = 'ferije7';
export var innerContainer = 'ferije6';
export var root = 'ferije2';
export var showMoreTag = 'ferijed';
export var tag = 'ferije8';
export var tagColorVar = 'var(--ferije1)';
export var tagIndicator = 'ferijee';
export var tagInlineMode = 'ferijea ferije9';
export var tagInnerWrapper = 'ferije9';
export var tagLabel = 'ferijef';
export var tagLabelMode = 'ferijec ferije8';
export var tagListItemMode = 'ferijeb ferije8';
export var tagRemove = 'ferijeg';
export var tagsContainer = 'ferije3';
export var tagsListContainer = 'ferije5 ferije3';
export var tagsScrollContainer = 'ferije4 ferije3';