import "../../component/src/ui/modal/styles.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../component/src/ui/modal/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA61W227bOBB991fMBihgA6EhK46TyChQ9BN2+5DFIg8jaWSzoUiBpG8p8u8LkrItKZZrt32yxbmcc2ZGQ315pV2hsSQDrw9V9KSn8GMAUGhV+j8AqsKM210C0XwA8D4AsKprmgTT++BLN939+XQAVqM0hdJlEv4KtPTvkMWfRmAyFDSMxk+z0U+g+9JE+ySTUS/Dmc/4MfEv8Tsl9LcZPnRreDrVJIo+fSxUH2wv2uNlaNGlUA1aJ9CeLkF7vkLb8zlteBnahdqe29rGAYN8RKUMt1zJBAq+pdz5cGnI1nOVYva60Golc5YpoXQCa9RDxrAouCTWMJcqRxGcPNIb4zKnbSegPg3e3g8lLzEw2L/Yk/uoNFAovUGdm3mD8th5M6lkIN+IdWctz1KlXAS3n4hYx0zgjnRbjYs+0DzkLS4pWs5NJXCXQCFo6zUKvpCMWypNAhlJS9odf18Zy4sdy5S0JG3TdFn1jrT+y9Fizfol1KaJ6Ygwkp5phXnO5YKlylpVJkByPTRYEENNyLyO2nYLcVRtT+AUKyGYyTSR/Hxj9YpuAmadOoEI/uJlpbRFaZvhF/ev9iwwp3/c2vmmqm7Efm+HaclWKc9YSm+c9DAaT+NbiG4hGt8/3sKkPWis4EK4GlLSGrKgLaS9e4F+JuO1ZZhZvg4q1pw2zL9sfiiYxJL29NKPmozgOX31BT4t6QGicXxngNDQ7xNvwF3BO+vh/TdfLO1p2k9/nrZHu4J17tImSddFiXxYt2N0mvvsz03ROfisB/7x2tKdA8l7QPBakLohC5+O1Y2KEpg3Hiftxzg8bnhul/vdVcf56i3JdbRtCXUtuWSnrHGw4vZgzVBk7kLL10tgcBf7DRVcatyjxwbYfocBpGrLDH/zCypVOncbX/n9XChpnYk6+9afp2hodPDa1CymkV/1wrntmU3Gs3lzCzpkiKeVxzjeGZoEunG+7n5Va9ICd6xCSc171qtaYq42ndhKVS6mNta+XrXGnK9MApM4UFMr63Qcl3D/lu+biVDtzmSEJnXm43jY6NgxvNnpo2uHeNQYz2XnRsbUKLGyvrpWVQnEtUodkrqm/MINy8MX2blBWbLZxzmZnZyT6X3QqhdcHi7i0I4D4nePuFE6Z6kmfE3A/zB34l+yJbfETIUZJVBpYhuNlW/nmnQh1MYftKP2rfXyPt8UKAzdvHS+bbj7CGG0JmlNdybage1x6Ebiyqr54P1/xTouDroNAAA=\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var closeButton = 'k7p09rh';
export var heightVar = 'var(--k7p09r1)';
export var minHeightVar = 'var(--k7p09r2)';
export var modalContent = 'k7p09rg';
export var modalContentWrapper = 'k7p09rf';
export var modalDescription = 'k7p09rj';
export var modalHeader = 'k7p09ri';
export var modalOverlay = 'k7p09re';
export var modalVTScope = 'k7p09r3';
export var widthVar = 'var(--k7p09r0)';