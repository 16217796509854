import "../../core/src/components/workspace-selector/user-with-workspace-list/workspace-list/index.css.ts.vanilla.css!=!../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/workspace-selector/user-with-workspace-list/workspace-list/index.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA6VT0XLbIBB891fcS2eSBzxIcVKXfA2Gk3QtAgaQJbeTf+8IW45ly2nTPnLA7t7e3vogt5yQw68VgKbojTwIqAwOryuAnnRqBBScfxmPY5lpCqgSOStAOdO1drxp5cAapLpJApQ06qHgfN8AgyfO/fD4unpbrY9MxX8y1dILKP1wAVkuQ37vYqLqwJSzCW0SEL1UyHaYesR3rI3Pr73UmmzNDFZJQFEeq60MNVm2cym59vT2zPu0zHtfu3LGBQF7GR4Yk1VFFtm+ZAmHxCIqZ7UMh8drZVfWuD2GyrheQENaj42cBW2WBUlDtWWUsI0CFNqE4Zqjcjax/jTAZ87PtUg/8Upxrg8x6zRjYRp8yY9guZ93mWgM+Ugx99JQQpYHIcC6Pkh/of856/8b3lsTbnz6ZxUvWcWdWZFylvlA7TSpC7XFyyweXzPOSdU8Odt8Nfm2mUdQAD/l7/z+26cGexP8nMiYZEgzmq0foDhx5zBMB9WFODbvHU2YOxc0Bhakpi7mr9njIG2kY8x3Uv2og+usZtk74OsyXjlUTgQfmHu5CGcD5J+TEduFRJYf8i0s3mwRNsdFWIzLcgbvhu7tN4w28o1pBQAA\"}!../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var ItemContainer = 'ya80ie9';
export var ItemText = 'ya80iea';
export var account = 'ya80ie5';
export var scrollbar = 'ya80ie7';
export var workspaceCard = 'ya80ie8';
export var workspaceListWrapper = 'ya80ie1';
export var workspaceListsWrapper = 'ya80ie0';
export var workspaceServer = 'ya80ie2';
export var workspaceServerContent = 'ya80ie3';
export var workspaceServerName = 'ya80ie4';
export var workspaceTypeIcon = 'ya80ie6';