import "../../core/src/modules/explorer/views/tree/node.css.ts.vanilla.css!=!../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/modules/explorer/views/tree/node.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA61WbW/aMBD+zq+wkCa1H1wl0NJiVGn/Y6qmI76EG46d2Q4tnfrfpzgJJCFQpu4TxL7X55678/ct7lMLOTr2M/69K5bxfM3+TBiLvoUfxkwBCfm9YPFqwtjHhLHFhbvH07uovXu6oLe8oBdHZxU/Jndt2HEQSYwyVjDSG7TkV12BWRCQ5AoF+0pEkUaeKnyrbIGiTHPymDvBEtQebXW8Nlai5RYklU6w+yIIe3zzPGgIpjD1qxHPjL2S9BsRwq8+c9J8g5RtvGDzqDZUOrTcocLEC6aNxmCptK4yVRhqwyhAStKZYFEbQmq0547eUbAd2BvOIU1DPuE8vw1axpEnowWzqMDTLpjPwWakuTeFYCGMLkZiY3ZoA1JrSLaZNaWWAxdBhId8b/vaPyR44JBUrp6n3pY4ffmaLUkO1gplz1oLkMQUStVFv2c7VMlhYrQEuz84YS2wHHeovWuBH3FuIctIZz3nR37ePfSU5n1+fUKshhyB5JVoQ+nxkmVQCBbPBsW6Dw7Hk+lagrUzqvThtGJr4zUQgMcNEw9krT9bosZDhjzUIOzQpsq8CrYhKVEfmuJ4gUpR4cgF4xvyyF0BCVYBvlooLkDTgWOsrLtZXdnCUg52HyoaWrmNuSfduekTbPFP5bK16eg8sv259TnFmk4bxNMfcEMrpMkTqH4Qh8OO9cfx5H6VzlNatYL2qH03wcs0nQ1o0X6fqQ8lRvfq05lWsyGjnkKsLf8WA/4139fW6UKGg1E+a0a5Bd0iGf6nxuYsupu56/PrJlNPjsQoBYUbzK2DfcGs8eDxhi8jidmoidHJ1xk+86to9vSlgb48hj0KEUNwyElzU/Z37fLrMECfwieZgTBa7XmyISWHm/20IZIg0l19Tf2bzcrrwVjD0ipFZ7dox7K8jO3xoiVMZxEduqtppwFFF4NOwWbgX4wHa+i9xaoyztsyqaSfpzlssYZr+lLLOFRpveRQhsn9PE1BOZy+CEh9w5pDJ02nvUZcK5Nsz4/Dd05aHub4ldRr99NYGer3Sge1BFRyE96G/EThtr/DqgfY/4aovjcF6jHUQFMONSrHd3XsGOm0oieGjQV2Nfn4C2X1EdqCCwAA\"}!../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var collapseContentPlaceholder = '_1qvp913a';
export var collapsedIcon = '_1qvp9139';
export var collapsedIconContainer = '_1qvp9138';
export var contentContainer = '_1qvp913c';
export var draggedOverEffect = '_1qvp913e';
export var draggingContainer = '_1qvp913d';
export var iconContainer = '_1qvp9137';
export var itemContent = '_1qvp9135';
export var itemMain = '_1qvp9133';
export var itemRenameAnchor = '_1qvp9134';
export var itemRoot = '_1qvp9132';
export var levelIndent = 'var(--_1qvp9130)';
export var linkItemRoot = '_1qvp9131';
export var postfix = '_1qvp9136';