import "../../component/src/ui/avatar/style.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../component/src/ui/avatar/style.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA7VW25LiIBB9n6/gZaucKnFzvzAv8yuYEO0dBAuIjrs1/74FSZRENztjjS8iAc7p06fp5PWNnRpFd0wjvUvCFCL05wmh4IcbEDJyTxBOkh8vbspZYwjCYdjPjaJCN1LtCNpRo+B9gYNVVC4RDlZltkTBqkzcJE6XKFii4Nme+3hCKMwGigGzGDAdZTpLkccONSstRdZR5PmEIo4mFPmIIQnmGMrcgUaxZQizTlE5YUiKqYh0RBGXcxRFYbGTopPifosJQZY8WkIRPNyHMHh0OX08vU7rOB7Xcc8UB/v3kUGX+S3q3pvOqLNP83Uc5xOKfJai6HxJI0sR91Kj/1VyNNERzukIHWgQuSGMPlnK0URHNC/jjlr+fhFXxfwIM7xyflBVfTytuiJOHM8RarMljteubxlstuYy30sNBqQgSDFODRyYfSoPTDVcHgnaQl0z8XJBTX3UIh6BFuEEk6615K1xmGe5bs+gtZvcIVTuaQXmROyinTfADVMErXmrFgeqFhh3AYfPzyMOLBVsQBBUMWGY6ge7hQrY0S7woQ3EGjGqGQaBZWtQI9WRqlojEA0I6ISdj+E9pyesDTWMoD1tNau9xGUucbc3q1YIEBtvd+6nuSxGaS7zmTSPmuS4Rd7XIB+Q6CNwjqstFRtGXIxLG/bycvSmG9F3ulF8yY1yfJWSsR3JjB2q23Oz7O/O7Mi/wveve6PG/kv0v36cVVKn8nZHqEHbFBEEgoNguOHMdSk7Yr1VIN4ICjywtQMbFAQEtUIz83JJoy+wi3RI6PVKI4XBGn4zgirKq8V4B/qJoudRkEN0lMNGYDBsp4lXf79abaA54UoKw4Txlw5MGagox+4oQTuoa+4y0GqmsGacVYYgIQW7q31Wn2nKcv2LVQY3YEOzYB5A/SmAqwC+JTlrWr1tlGxFjSvJpRqsok1ja2KvYEfVqVtzhtzaddyCYW7VFdI59LPPx/5JGvj1xL72NvPv4JXyGYn/SMq1crVZ00UWLFEWLlHmvpJTp+o3BlGz917RzTy1yk/DpQ2cr2svol9BqzDVDqxV2qLtJUyuLiNb67nL0Rkv9DY0k7t9q0cV3YfH6IvtABrWwB3epZZ8jZf20ccwYvTPu//2Mn38BWDnVBM5DgAA\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var DefaultAvatarBottomItemStyle = 'sm415i7';
export var DefaultAvatarBottomItemWithAnimationStyle = 'sm415i8';
export var DefaultAvatarContainerStyle = 'sm415i4';
export var DefaultAvatarMiddleItemStyle = 'sm415i5';
export var DefaultAvatarMiddleItemWithAnimationStyle = 'sm415i6';
export var DefaultAvatarTopItemStyle = 'sm415i9';
export var avatarFallback = 'sm415id';
export var avatarImage = 'sm415ic';
export var avatarRoot = 'sm415ia';
export var avatarWrapper = 'sm415ib';
export var blurVar = 'var(--sm415i1)';
export var hoverWrapper = 'sm415ie';
export var removeButton = 'sm415if';
export var sizeVar = 'var(--sm415i0)';