import "../../core/src/components/tags/tag-edit-menu.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/tags/tag-edit-menu.css.ts.vanilla.css\",\"source\":\"LmxpaGx5MSB7CiAgdHJhbnNmb3JtOiB0cmFuc2xhdGVYKDRweCk7Cn0KLmxpaGx5MiB7CiAgZGlzcGxheTogZmxleDsKICBmbGV4LWRpcmVjdGlvbjogY29sdW1uOwogIG1heC1oZWlnaHQ6IDIwMHB4OwogIG92ZXJmbG93OiBhdXRvOwp9Ci5saWhseTJbZGF0YS1yYWRpeC1zY3JvbGwtYXJlYS12aWV3cG9ydF0gPiBkaXYgewogIGRpc3BsYXk6IHRhYmxlICFpbXBvcnRhbnQ7Cn0KLmxpaGx5MyB7CiAgd2lkdGg6IDIwcHg7CiAgaGVpZ2h0OiAyMHB4OwogIGRpc3BsYXk6IGZsZXg7CiAgYWxpZ24taXRlbXM6IGNlbnRlcjsKICBqdXN0aWZ5LWNvbnRlbnQ6IGNlbnRlcjsKfQoubGlobHk0IHsKICB3aWR0aDogMTZweDsKICBoZWlnaHQ6IDE2cHg7CiAgYm9yZGVyLXJhZGl1czogNTAlOwp9Ci5saWhseTUgewogIHdpZHRoOiAyMHB4OwogIGhlaWdodDogMjBweDsKICBib3JkZXItcmFkaXVzOiA1MCU7Cn0KLmxpaGx5NiB7CiAgaGVpZ2h0OiBhdXRvOwogIGJvcmRlci1yYWRpdXM6IDEycHg7Cn0KLmxpaGx5NyB7CiAgY29sb3I6IHZhcigtLWFmZmluZS12Mi1idXR0b24tZXJyb3IpOwp9Ci5saWhseTggewogIGZsZXg6IDE7Cn0KLmxpaGx5OSB7CiAgb3BhY2l0eTogMDsKICBjb2xvcjogdmFyKC0tYWZmaW5lLXYyLWJ1dHRvbi1wcmltYXJ5KTsKICB3aWR0aDogMjBweDsKICBoZWlnaHQ6IDIwcHg7Cn0KLmxpaGx5OVtkYXRhLXNlbGVjdGVkPSJ0cnVlIl0gewogIG9wYWNpdHk6IDE7Cn0=\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var menuItemList = 'lihly2';
export var menuItemListScrollable = 'lihly0';
export var menuItemListScrollbar = 'lihly1';
export var mobileTagColorIcon = 'lihly5';
export var mobileTagEditDeleteRow = 'lihly7';
export var mobileTagEditInput = 'lihly6';
export var spacer = 'lihly8';
export var tagColorIcon = 'lihly4';
export var tagColorIconSelect = 'lihly9';
export var tagColorIconWrapper = 'lihly3';