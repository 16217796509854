import "../../core/src/modules/open-in-app/views/open-in-app-page.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/modules/open-in-app/views/open-in-app-page.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA62U3XKjMAyF7/MUvmwu3IEs/Vn3YXaMEaDW2B5bCbSdvvuOTboFEtJ0Zq+wjWSdT0dw+ydvDJSkM/a+YawFbFoSLM+yQ/u0YazHitpx38d9hcFp+SpYrWGIB8/7QFi/cmUNgSHBFBgCH19JjY3hSNCF6XFtDfGAbyDYQfobzmVdowGezksZYBujnA1IaI1gHrQkPMDT5mNz+yk3T3KV1dYLhqYFjzQL2KWAr1tkGazeE8S7yTrBsrjSUNNx6Ufy7CzlCsoJfHBSAS+BegCTMGRVoWkEy+/dwPJd5oaZzF9J5kk9ZfW+M7yRTrBikVJM0WcdJBiIO4+d9FGTtn77bcND9xXTH92/y1IX0nUVKOvl2ENjDcygisj0uNB39z1SbMYs536VSaN5WbJcq/MacLX3IRZ1FkdXJ6oezpPEJ6/QgxrLjWQXxqSTvkHD09gVywl4TEWmIeOQ/DsrLZHtBMt3i8zfq5k/kOxtf/Rk59ZHfVJVpqo/8uFaXy9aNZFQ/idfTtjP/y4+DSiWk67O61ipNlY6OnSRdwjbtbalHgdQ1lTTj/zjL/Pd903IBQAA\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var accidentHandling = '_1gnebtl9';
export var affineLogo = '_1gnebtl1';
export var centerContent = '_1gnebtl7';
export var docFooter = '_1gnebtlb';
export var docFooterText = '_1gnebtlc';
export var editSettingsLink = '_1gnebtla';
export var prompt = '_1gnebtl8';
export var promptLink = '_1gnebtl6';
export var promptLinks = '_1gnebtl5';
export var root = '_1gnebtl0';
export var topNav = '_1gnebtl2';
export var topNavLink = '_1gnebtl4';
export var topNavLinks = '_1gnebtl3';