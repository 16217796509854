import "../../component/src/ui/notification/desktop/styles.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../component/src/ui/notification/desktop/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA31U227bMAx9z1dwAQYsDyrsNO0yBf2SoRhoi7bZKZIhMXHSof8+WM7FSZM+mqTOTZQf/iwLxPfiGf5NAAofDAUV0PAmali2u1Wq7lRs0PhOwxbDD6WwqtjRoajyWT/ltxQq2880bAy51eRj8nBA/3kLnV1DgWV17nRspNGQH2lTMcrekoboLZtUxvJvHfzGGVV668NR04Eqm43O3hqYp4Fbnd7HWfMyaW7RGHa1hvy5V3Vu/0ptw7G1uNdQWUqi0XLtFAut41BUUTAkk2+bKFztVemdkBMNscWSVEHS0WVcmLAb4roRDfPFkMfXXCU5oTBGKRLKIdO7IK3tRVyBAKwx1OxUGCTk2aX5AuK2HsPnWfZ9NdJ8/L4V8+Ii5nIMk/VnUmp16Dcpv71YAEI7UecGWctt5Ni3uoaFVMpWg/NdwDaheieqO8hbZInJ9kt8HXMajPxOGvKnofR1GCY5uJq52hZKM7fSeEzr2PrIwt5pCGRReEuXq65BArrYYiA3ejG9QUdjouq3QUFVWY/yMpWwoenrsMgnBiyitxtJDOLbo1iAO9rru9qfZvCN160Pgr2q85FmHEniePoU7sWfZKivZ59vZT6IGWx1LI3i0rtXOFENnfQWXqbCYk+Oh6erLFWi4TFd78d/hTLd3vAEAAA=\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var action = '_8baazbd';
export var actionButton = '_8baazbe';
export var actionTextColor = 'var(--_8baazb3)';
export var card = '_8baazb7';
export var cardBorderColor = 'var(--_8baazb2)';
export var cardColor = 'var(--_8baazb0)';
export var cardForeground = 'var(--_8baazb1)';
export var cardInner = '_8baazb8';
export var cardWrapper = '_8baazb6';
export var closeButton = '_8baazbf';
export var closeIcon = '_8baazbg';
export var closeIconColor = 'var(--_8baazb5)';
export var headAlignWrapper = '_8baazba';
export var header = '_8baazb9';
export var icon = '_8baazbb';
export var iconColor = 'var(--_8baazb4)';
export var main = '_8baazbh';
export var title = '_8baazbc';