import "../../core/src/components/page-list/components/page-display-menu.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/page-list/components/page-display-menu.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA62T0U6DQBBF3/sVG5/swzSAbW238UuMMQsMdBSWzTItVOO/m11shUqjTXwiWWbPvTNzd/YcNpkhGwfifSJESRoaSnkrRRQFpt1MPiazY0nYlSibk4YCM5YiXLoaIaDB+JUYlDFgMadKS6ErSK3KB4jII4xKU9I5WMq3LMXqTOfOF2WVZqjpDaXYK3sLoLKMNII/b+upk80KbKGxyjg19x1yHlPFClTCtMeHG7Y7vHny7KQqKnvGNZZKZQ/g/00HoLm/lFJtCnWQXtWpq4JyDcRY1lIkqBmtO37Z1UyZA2lGzVLURiUIMXKDqF1F7gyvusn5dhrsJrEIgs2fWu+ZW3xP64iZd5ixLhlbhhqTSqcXml32VyTF3LQijDqv2y9+1Pf+24pG+rvC2ClvccVcld7PwO79f9rtcVfjO+8lrsubM9ie3kwQdFL9DY946+msLwbSTySlWsUFji1qfVW8Pe1Hxj8BspNb7P8DAAA=\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var currentGroupType = '_1wfpirb5';
export var headerDisplayButton = '_1wfpirb1';
export var listOption = '_1wfpirb6';
export var menu = '_1wfpirb0';
export var properties = '_1wfpirb7';
export var propertiesWrapper = '_1wfpirb8';
export var propertyButton = '_1wfpirb9';
export var subMenuItem = '_1wfpirb3';
export var subMenuTrigger = '_1wfpirb2';
export var subMenuTriggerContent = '_1wfpirb4';