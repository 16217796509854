import "../../core/src/components/sign-in/style.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/sign-in/style.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA51UTXObMBC9+1fssT3IA8TYqXLrH+nIsGDVQmKkJbbbyX/vSIADGBLSk2ZW+/bj7dvd/ooPlThfmgj+bgAqYUupGZmaw1NUX182b5tt7xIvuABkRhnL4VXYb4yJopAaGeGVmMPM6FzYGwsu371zYTQxJ//gBBDsVxd8lDecUJYn4hBv00kdIlQyl1VJfb7nGmG2/qvFNdZ5YG2kJrRLDSyFSgY0cBANmZd3Wo6GyFQcdi0xgQShZKk5ZNhmG4R6CqFy6WolbhwKhQH1u3EkC8+ZJtTEwdUiQ3ZEuiDqmWwPo9qtIwjgKLJzaU2jcw5khXa1sKgpfBmbo2VdkMnnl4eYJC0jjUPLHCrMiIM2Gkd1p6HuHnM4tJgHgvzLcmkxI2k8tUY1lZ6lrmcdIIyBScLKDc0fSn6/ckDv4UpRc3hu654l4EPmXDXW2mFxjkFZtZXVzHJdupT7KBpFew7RLjKnE4ddukqiPx6WPu6WvrMpLIgDS0fGXpjxfvlAvCZtE16R466X13sZc5yfVBhH3FG/oID7hMaNtc0m830lE6FkIb3PyiEeBo0Gq3RH11dwRsn8oTklbmiZ1E7m+LMH+WfcbP4pQffDu/LkDoLjp8E72a2+5iNRphNRFmuH/cU2yv8Q0WleRMvnZlZRb/8ATZleAFUHAAA=\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var addSelfhostedButton = '_17makwu9';
export var addSelfhostedButtonPrefix = '_17makwua';
export var authMessage = '_17makwu1';
export var authModalContent = '_17makwu0';
export var captchaWrapper = '_17makwu2';
export var linkButton = '_17makwu4';
export var passwordButtonRow = '_17makwu3';
export var resendCountdown = '_17makwu8';
export var resendWrapper = '_17makwu5';
export var sentMessage = '_17makwu7';
export var sentRow = '_17makwu6';
export var skipDivider = '_17makwub';
export var skipDividerLine = '_17makwuc';
export var skipDividerText = '_17makwud';
export var skipLink = '_17makwuf';
export var skipLinkIcon = '_17makwug';
export var skipSection = '_17makwuh';
export var skipText = '_17makwue';