import "../../component/src/ui/property/property.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../component/src/ui/property/property.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA81X7W7jKBT9n6dAkUZqpVI5aeqmVCPNG8wDVNWKmGubLQYWcD521XdfGfydOE06s9L+aOsQ38vl3HPOpfd/PD+bh/VfEfpnhhDjVgt6ICgVsH+ZIVRwiXPgWe4Ielhqv6aV5Y4rSZABQR3fgl+ljHGZEbTUexTpvf9bR1TZ8M5QTVD1+2X2Mbtv9n1lhmYZ3Qj4PnemhPmbryQpjVWGoMzQzSfvvzLqKPbLXGaDLFtu+YYL7g4E5ZwxkJ/kIhtIlYFQgpIOpCNoPn/pY7MRKnkfAkE3VonSeSCGlSPklCboMfpWPQtIHUFR9bhRhoHBhjJeWtIgtaHJe2ZUKRlOlKjSbKm5wZimKZeAt0vsYO+wFjSBXAkG5tbvYai0qTIFCY+COrjBsd7fIfwYfeveqesNLUXR/dKi6H5h75DSNOHu0C1VIfViXXHDg1UodceZy3ufhMBJTmUGTfo26WeY09SB+R9B7pHS1IB00+CuB+A22CyisGsNzvrXwcnVFkyflu1Wdf1tkxb9VAv/bhWbCrXr2N/FR/pI4fVSK+X68ykVVa3g0oHBsAXpLEFSSehXsDztKN4LGDeQhD4mSpSFT/hnaR1PD7glgXXUuGnHGbWzJmKqpMOW/w0j6YT14nZwwFjv259Vz6tsbrh8r2kjqvAGomWNemnBYAsCEtccvaLvtGQtJEoyag63PX4sYg9xD7RgZjm1uABZnrTEGvgLwgJ5fPDnxiLoAQzuveeDf27BCFoV/THrjBZrA1sOuzfUVnB2r4GiurIfLqOIUbtqmQqeScwdFJagBAIGCGXVWImHMK5OJ57IcES87qvTltHj2CIOSxOg8kTJQeu7Gh99jf6smakkuniZUKznT/cFCMG15d6hdzl3gK2mCVQ0DNP1EhF0dcRfb0LQyn+l0pO660u3KXNkfTFJVVJa7G1LBNNUpavSHbtUTEhvmNZD6NLB20sTtGGAMiXF4fs8pcK2dxMvjckrzgk9X5mOcHsT9HeH6sPvuMu5vP1t2m8qe/IZAwssiHRMgksae7lNdpOo6Xd7GY17RNo1N5MomnKEJ2S3WVDc7xDvU213TdJzGbThxTh+fe1w9GdaD8/0fJXL+QzL8Yhfhel3dNcfzSV6/VarkUBPeVu3wcZv0I7FYbMX8Zd89xTwyVUHuYTNAwY+BgZO2PI5AJKg7UQJQbUFNrSKCxXTpWM+rn/np0KE2z1QC5hLrEp3OYIDLJYjdrBztfeuz0a56u78HDHIzjnBGeHBl4RTc7GgJuMS+38PVsMjwGtoydswfzMufhTAOEXacOn8G0M/PIpA6GP28S9VcAqbXA8AAA==\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var hide = '_99r38q1';
export var propertyIconContainer = '_99r38q4';
export var propertyNameContainer = '_99r38q2';
export var propertyNameContent = '_99r38q5';
export var propertyNameInnerContainer = '_99r38q3';
export var propertyRoot = '_99r38q0';
export var propertyValueContainer = '_99r38q6';
export var section = '_99r38q8';
export var sectionCollapsedIcon = '_99r38qd';
export var sectionContent = '_99r38qe';
export var sectionHeader = '_99r38q9';
export var sectionHeaderIcon = '_99r38qb';
export var sectionHeaderName = '_99r38qc';
export var sectionHeaderTrigger = '_99r38qa';
export var tableButton = '_99r38q7';