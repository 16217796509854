import "../../core/src/components/affine/empty/style.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/affine/empty/style.css.ts.vanilla.css\",\"source\":\"Ll8xZmlsMW84MCB7CiAgd2lkdGg6IDEwMCU7CiAgZGlzcGxheTogZmxleDsKICBmbGV4LWRpcmVjdGlvbjogY29sdW1uOwogIGFsaWduLWl0ZW1zOiBjZW50ZXI7CiAganVzdGlmeS1jb250ZW50OiBjZW50ZXI7CiAgZ2FwOiAxNnB4OwogIHBhZGRpbmctYm90dG9tOiAzNXB4Owp9Ci5fMWZpbDFvODEgewogIG1heC13aWR0aDogMTAwJTsKICB3aWR0aDogMzAwcHg7Cn0KLl8xZmlsMW84MiB7CiAgdGV4dC1hbGlnbjogY2VudGVyOwogIGZvbnQtc2l6ZTogMTVweDsKICBsaW5lLWhlaWdodDogMjRweDsKICBmb250LXdlaWdodDogNTAwOwogIGNvbG9yOiB2YXIoLS1hZmZpbmUtdjItdGV4dC1wcmltYXJ5KTsKICBtYXJnaW4tYm90dG9tOiA0cHg7Cn0KLl8xZmlsMW84MyB7CiAgdGV4dC1hbGlnbjogY2VudGVyOwogIGZvbnQtc2l6ZTogMTRweDsKICBmb250LXdlaWdodDogNDAwOwogIGxpbmUtaGVpZ2h0OiAyMnB4OwogIGNvbG9yOiB2YXIoLS1hZmZpbmUtdjItdGV4dC1zZWNvbmRhcnkpOwogIG1hcmdpbi1ib3R0b206IDA7CiAgbWF4LXdpZHRoOiAzMDBweDsKfQouXzFmaWwxbzg0IHsKICBwb3NpdGlvbjogYWJzb2x1dGU7CiAgdG9wOiA1MCU7CiAgbGVmdDogNTAlOwogIHRyYW5zZm9ybTogdHJhbnNsYXRlKC01MCUsIC01MCUpOwp9Ci5fMWZpbDFvODUgewogIGRpc3BsYXk6IGZsZXg7CiAgZ2FwOiAxNnB4Owp9Ci5fMWZpbDFvODcgewogIHBhZGRpbmc6IDhweCAxOHB4OwogIGhlaWdodDogYXV0bzsKICBmb250LXdlaWdodDogNjAwOwp9Ci5fMWZpbDFvODggewogIHBhZGRpbmc6IDAgNHB4Owp9Ci5fMWZpbDFvODkgewogIHBhZGRpbmc6IDAgNHB4Owp9\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var absoluteCenter = '_1fil1o84';
export var actionButton = '_1fil1o86';
export var actionContent = '_1fil1o88';
export var actionGroup = '_1fil1o85';
export var description = '_1fil1o83';
export var illustration = '_1fil1o81';
export var mobileActionButton = '_1fil1o87';
export var mobileActionContent = '_1fil1o89';
export var root = '_1fil1o80';
export var title = '_1fil1o82';