import "../../core/src/modules/peek-view/view/image-preview/index.css.ts.vanilla.css!=!../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/modules/peek-view/view/image-preview/index.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA61V246bMBB9z1dMHyrtSnUE5Fr3pX9SGTxJ3IKNbLNht8q/VzYmGBLSbbVKlOBhPD4zZ854+SNdp1K9JAn8XgCcUBxPlkKaJJ+/LQC4MHXJXikcSmydgZXiKImwWBkKBUqL2pl/NsaKwysplLQo7fDqslj2J6T+hFoZYYWSFFhuVNlYdPuFNGgpJO75LLg9DRDGkKJ42YN4N7jdP+FCY9E5F6psKvkQ+myyPaLVtm4jwP0yV5qjJppx0RiHuzNbVVNIlhuNlVvqLsZgyFnx66hVIzmFF6afCGGHg5BIzidh8XmITEEq6bOsGedCHuMoRaON0hRqJXq4hSqdZRRTFEoS/8IHtprJvowDDkiWmQFkBomQRDXWub4RITm2FFK3qpg+Ckl8bqt9l2iwhQQ7a0TbytN2D9SQ6H1SD0paYsQbUtiGopZuX89Hb/R+52DMVcmdUdWsEPbV1Sob1w7STbfvpnYR6rVHfdNWU71cq5PdbS1TswJJjvaMKGc7LDp2c//Y+W6eadmYtSj89oPCNwY1MVhiYfvujE7ZvYPxuJGy9aRl9qPZ1OusYi0J0nOPocgTboPvTYr/rvojqyns70p8HVu9GusWjCoFH6cbtg26G7RGHpcnVy0xJ8bVmUJSty4tSN2PW+hjzp6SLxC+y3Q97BFvvhAhTZKr9p0j4TGlX+83zv/P0izUsBKyJ3UVTFc207qFLJJ4NwrSabcwjy0E8VzcU+rsuL0h6SPvwHzUydmDDKNCbJKZpP9KJHwSVa20ZU4XMQx6Ui+oPZi/9KB3vFYjilF80OwYZv1BtMi7zrVWVRSyILd4qkYIuEcQjYHtfjtflkhMEfVBO/6z3G2ex3QkoxvtCiuddBx6IMMNM71SwxuY3qaXxfcKuWBgCo0ogUkOT1E+u+2+bp998MmNMFvNS+y7C74WW0v8hrHr5Q+ZU302/gkAAA==\"}!../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var captionStyle = '_141nov0d';
export var cursorStyle = '_141nov09';
export var dividerStyle = '_141nov0a';
export var imageBottomContainerStyle = '_141nov0c';
export var imageNavigationControlStyle = '_141nov04';
export var imagePreviewActionBarStyle = '_141nov08';
export var imagePreviewModalCaptionStyle = '_141nov07';
export var imagePreviewModalCenterStyle = '_141nov06';
export var imagePreviewModalCloseButtonStyle = '_141nov02';
export var imagePreviewModalContainerStyle = '_141nov05';
export var imagePreviewModalGoStyle = '_141nov03';
export var imagePreviewModalStyle = '_141nov00';
export var imagePreviewTrap = '_141nov01';
export var scaleIndicatorButtonStyle = '_141nov0b';
export var suspenseFallbackStyle = '_141nov0e';