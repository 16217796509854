import "../../core/src/components/blocksuite/block-suite-editor/bi-directional-link-panel.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/blocksuite/block-suite-editor/bi-directional-link-panel.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA5VW246bMBB9z1dYK1VaqjoibLIXtitV7Uvf+gFVHwY8EHeNjWxDQqv8e4UhBFhnkz6tdjyXM2fODFk2mwdIREj+LgjZcWa3MVmF4YfnBSEF7GlvqkHfUgpZxiVSZNwq3T0FnaPOuaQCMxsTqKwaGTXPtyNrCYxxmfe+vrSGM6S92ycSrYNxWJ/t6rhMSUsN/4OzGGdPwGDwvDgslh0JK0fCFrsaq/ty//yWFMZNKaCJSSbQvf+ujOVZQ1MlLUobkxSlRd0+geC5pNxiYU7moVzkyiWQvuZaVZLNICZKM9Q0VUJp18sRWLjceJANae9c2sswTQkp0gTtDlFeRrt2aR1vux7IJgxnHK96ZKJt4Ig3WndG18msSYt7S0vNC9DN0OpQczMZSPTY5emJ0cB4ZWIyscZkVe6JUYKzC2yeeKc+YLstt+gcHUTHzXi046YjX9M9LB9hVxNBSFpp0/qWis/mce+46ZcsUdaq4qjZwefB+ZwtZzBVkk0LHvHf+ZrqjEP6R9/RGHzDLsEbIXplRkgOZUx6pTjyqRNoTKTaaSg9g19PwcRbVaOe7ZR3tnVEBTSo6cjPBf+oUQtogkmPyz5KY4YaZYrUciuwK9QhOtIvlcRJrKnzsyOoI8pTJU9TCDzdXJOgV804/Ml/A9q/lHGNqeWqlbMSVSEH9o9C7kVl1Wki7+sMXLnpcc2g4KLxnV0D0vTPE40zzKASdrzM7tT51rmO3PAEl6+GJkKlr19dSHD+QPSfhuH/M5SOF/GaQ+FBMgR8mx80+C+RzvJ+b0Mn2cjHThxzAgePZPL+9oik7t1qkIZ3kgAhSLiMDEEwSLmkqrLvsDUT4OQuRuFEJOlPBhbaXgWUBtnLjdUV3vw6QciULmKilQWLt08hwzy4VHq6PF8KZBxIqbm0Lu1y/OtmtBDdohJyaIPaLyJwiZrUHHel0pbcuqNGPr+Q+3VY7gNfskFP4fCFOywO/wBFpz33UAkAAA==\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var collapsedIcon = 'y57ablc';
export var container = 'y57abl0';
export var divider = 'y57abl2';
export var dividerContainer = 'y57abl1';
export var link = 'y57abl8';
export var linkPreview = 'y57abla';
export var linkPreviewContainer = 'y57abl9';
export var linkPreviewRenderer = 'y57ablb';
export var linksContainer = 'y57abl6';
export var linksTitles = 'y57abl7';
export var showButton = 'y57abl5';
export var title = 'y57abl4';
export var titleLine = 'y57abl3';