import "../../core/src/components/page-list/docs/page-tags.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/page-list/docs/page-tags.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA6VVwW7bMAy95yt0KdAAVeA4yZYq2JcMOygWbXNVJEOim2TF/n2Q5Di2m7YIdgkc0iQfH/noRXWuVptmyd5mjDXWI6E1gjnQkvAVdjPGjqioFmyZZQ/hbw1Y1XT9f0DDL7ZV3px2s7+zRcqax6wKfaPlWbBSwylESI2V4Uhw8IIVYAjcIGgVg+wruFLbIz8JVqNSYHYfApwiqmQj2HaEZB2TBgD86II7/O4uJoUOipS3sLo9mHco42uepKO+wHpUYHO71ZSOXxF90P2wNbn3Vrd0s7WDPPHJPMhJ04dqzbJF7lm2WHkG0gNHw21LA6RLUQdy2Qj4IO+rdI+cJ2c2HwR+m+zIECfZRrAsPGkoqXt0CXt2qxHbyALp3Hlv9RDse1m8VM62Rgmm0YB0vHJSIRh6fM4UVE8ptJEODLHs4amDL8sSDfDYKS+sto6XqDWoOVtnD/OP6UhN9uiWgze/R9elkTxP43w38qCHjsrsk3n/bj1heeaFNQSGBPONLIDvgY4Qlv1aVmjpiRc1apVmdS0QxtYlGERs75Dd10AYa6RSaCrBsssOR0bFmGuCE/HG4UG6c6J8HkdonbrMYBIxdA1Hsv2pJEle2qL1oH6Qa+FXbOmTXNPCfbLnpHxriHv8A4KhqcEhDbCFjWp92M3mnWjXydS9eZHeyOjprEEwbzWq6dKO++0dN3gaKjvvaJ6KJlJ8uYyCgdbYeIxKOdZIwOPoBDM2nbeeBDklYYQr2Q/z0azXzYkt8/eEdNDuhXJtZpWNDuf+a2g138yn9+R6hjxh8XIeSRGNjsFx8ftKRazUcTxheDua6GUhNon2dPxrh+YlVu8zqtGnavihup+e1odVAg0FBbOBQR34P0FfXf2CTfYrv6d9xorW+aDAxuLk4w3dNX37TAeDqxyU+g9V5uSQgwgAAA==\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var hoverMaxWidth = 'var(--gyg35p0)';
export var innerBackdrop = 'gyg35p6';
export var innerContainer = 'gyg35p5';
export var root = 'gyg35p1';
export var showMoreTag = 'gyg35pb';
export var tag = 'gyg35p7';
export var tagIndicator = 'gyg35pc';
export var tagInline = 'gyg35p9 gyg35p8';
export var tagInnerWrapper = 'gyg35p8';
export var tagLabel = 'gyg35pd';
export var tagListItem = 'gyg35pa gyg35p7';
export var tagRemove = 'gyg35pe';
export var tagsContainer = 'gyg35p2';
export var tagsListContainer = 'gyg35p4 gyg35p2';
export var tagsScrollContainer = 'gyg35p3 gyg35p2';