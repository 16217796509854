import "../../core/src/mobile/dialogs/selectors/generic.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/mobile/dialogs/selectors/generic.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA71U227bMAx9z1fwZUADTIHtuGmmAnvYn8gWbXNQJEFSbh3674MVO5HTpEUwYE+CJPLwkDzkAt9WtqIM/swAJHmrxJFDo/DwOgMQilrNKODGc6hRB3T98++tD9QcWW10QB0uX++zxQkvj3h7kqHjkGfZt96tQ2q7cLl/CNefTJLDOpDRHGqjthudwBYRtjbKOA474Z4YE01DGtmuYAEPgVlHG+GO88RpGZ3G4NkYiEOeGJXRyAopSbccMljbSMkaTycyDpUItMPE6TnNshw8rrO8IIjKG7UN2L8GYwcuCpsTrTPs6qFmnDkP8VthOeTF6VIZJ9GxyoRgNhyyxbM9gDeK5IfyKXFEx0h7kvhrdOuPtJYvacbLYprxeG+MDszTG3IoytPTnZZRbfStlq3/T5gfD8gJYN9RQOatqJGDNnsnbP9sdugaZfYcOpISdexu73v5QKXIevKvl6TuyFBERklm+errzCR5USlMM6uu5TziDIKI8sv/TQv1rRlP1TgEPTvIz5ZCWU4IOiFp68+iTivykjztR+9smKUQ0MUeRRIsW5TLCQd8qOUjuSKbgDRfggR0ga5R8vUEpb095veW4N0FMNlscfqHot1UphP6vI+Ugmyx9IDCp2ut5Z3wTz9hLNn8O9z+aeZTmfU9z+4DDebnOS6n5biOOjVefW78aaRV3BjvfwGv2GIz7AYAAA==\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var actionButton = 'ez6pbid';
export var actions = 'ez6pbic';
export var changedInfo = 'ez6pbie xn1iioi';
export var footer = 'ez6pbib';
export var headerTitle = 'ez6pbi2 xn1iiob';
export var info = 'ez6pbig';
export var list = 'ez6pbi4';
export var listItem = 'ez6pbi6';
export var listItemArrow = 'ez6pbia';
export var listItemCheckbox = 'ez6pbi7 ez6pbi0';
export var listItemIcon = 'ez6pbi8 ez6pbi0';
export var listItemLabel = 'ez6pbi9 xn1iioa';
export var quickSelect = 'ez6pbi5';
export var root = 'ez6pbi1';
export var scrollArea = 'ez6pbi3';
export var totalInfo = 'ez6pbif xn1iiom';