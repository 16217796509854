import "../../component/src/ui/notification/desktop/styles.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../component/src/ui/notification/desktop/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA31U227bMAx9z1dwAQYsDyrsNO0yBf2SoRhoi7bZKZIhMXHSof8+WM7FSZM+mqTOTZQf/iwLxPfiGf5NAAofDAUV0PAmali2u1Wq7lRs0PhOwxbDD6WwqtjRoajyWT/ltxQq2880bAy51eRj8nBA/3kLnV1DgWV17nRspNGQH2lTMcrekoboLZtUxvJvHfzGGVV668NR04Eqm43O3hqYp4Fbnd7HWfMyaW7RGHa1hvy5V3Vu/0ptw7G1uNdQWUqi0XLtFAut41BUUTAkk2+bKFztVemdkBMNscWSVEHS0WVcmLAb4roRDfPFkMfXXCU5oTBGKRLKIdO7IK3tRVyBAKwx1OxUGCTk2aX5AuK2HsPnWfZ9NdJ8/L4V8+Ii5nIMk/VnUmp16Dcpv71YAEI7UecGWctt5Ni3uoaFVMpWg/NdwDaheieqO8hbZInJ9kt8HXMajPxOGvKnofR1GCY5uJq52hZKM7fSeEzr2PrIwt5pCGRReEuXq65BArrYYiA3ejG9QUdjouq3QUFVWY/yMpWwoenrsMgnBiyitxtJDOLbo1iAO9rru9qfZvCN160Pgr2q85FmHEniePoU7sWfZKivZ59vZT6IGWx1LI3i0rtXOFENnfQWXqbCYk+Oh6erLFWi4TFd78d/hTLd3vAEAAA=\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "../../component/src/ui/notification/mobile/styles.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../component/src/ui/notification/mobile/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA31U22rbQBB9z1dMAwUbvGKluLG9odD/KCWMtCN7yGpX7K5txSX/XnSrojgOfrA0lzO3c/TrhV5LjxUFeE6P+SU8ooS/dwCld1X3AFBhI86s40HBel03T3cAb3cA0V37UylP5z7g7S4ZEdMu0FAZFUj4xlXtfEQbn+ZBhn9rjCiCs5a8iA5D/NOlDuglR1E4G8nGGUrbgt+zVSDrBvAY3Qw5u4HRJh6I94c4DZY7r8kLj5qPQUGW9eZrfIAatWa7b4cecrF42Xt3tFoUzjiv4IR+IcTzNke85HLZBn3mSZdTaQVp3UBwhvU8KBuCGhEOqN15xMCyZEuDUfRQmkNt8FVBaahrbY+1gm3fJhreW8GRqqCgIBvJt2Z3Il+aFvfAWpNtbdGjDRzZWdU/l85XIJM0zBessIh8om7P/+MUhAINLWSy2yzn8f2d0XKFkfTP++iPdN+f+sSBczYcX6dGvkot0YQx9xOi9oHdABO/ZbINUBxzLkROFya/SLLNKsm2qyR9WCW73XKWKko2RlROk4LS+TN6PZ//oVeMs1EEvpCCbKhu2suMFJO3zr+eb2f9ES3d9Gid6TwSVsqrCiNbDcVIXoQai46gQibrh951PnCkzkMKrDt7rGfFf3TFazdeveSGdEcFVw8jDDp+mjSVSvn9vZjG9ysazqgXIvo4F9Jj3+RFsNXUKNjtdru5shT4fY4Luep+STpf3abvfsSTkA3S/LqTSQSTTt6hbm+h9l8F0a3mYxK+/+h0y2pLK0hvXuGGBKmJYnKQMVwHnvMv72pdzdj+C82eiv6WhTPHqpXTP8ZjL/31BQAA\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var detailActions = '_1ubzs6ab';
export var detailCard = '_1ubzs6a6';
export var detailContent = '_1ubzs6a8';
export var detailHeader = '_1ubzs6a7';
export var detailIcon = '_1ubzs6a9 _1ubzs6a3';
export var detailLabel = '_1ubzs6aa _1ubzs6a4';
export var detailRoot = '_1ubzs6a5';
export var sonner = '_1ubzs6a1';
export var toastIcon = '_1ubzs6a3';
export var toastLabel = '_1ubzs6a4';
export var toastRoot = '_1ubzs6a2';