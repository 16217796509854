import "../../core/src/components/doc-properties/table.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/doc-properties/table.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA51W227bMAx971cIBQYkD8qcS7NWXYFhP7APGPrAWLSj1ZYESXacDf33QZLtKqmztntKJJGHh0ckrUXXPO2OckX+XBFCaVxljCy3me7uk70lIy2YGaVQFEIiLZR01NZzb3MQ3O0ZWWbZJ78MRwXUojpOOoG0/XHw1sC5kCXdKedUzcjy1kd+vlrEwOtAjQurKzgyUlQYeO1RlHvHyLrn2aMwsonrGkwp5Ah6G3d/NdaJ4khzJR1Kx4jVkCPdoTsgSm8BlSglFQ5ry0iO0qHx27mqlDnLpl1Rh52jFnMlOZiYTsjx0LO7ybIklc10Kv6XkeUkP39GUfLAoTHWk9BKDLSioOI3MrLKTmS7CbHeS3t02wa3UcyMbKNwE7F3ynA01AAXje11f8Fhe9WiCWg7yJ9KoxrJ6QU+FRzR0MQuOP9o0VRwQu/LZQUpFwZzJ5RkPu2mDtdZgh4vPxHrQimPcW4/GuekbKwD486jDwpyLKCp3P2EzGP4uxB+KPEsUdspzUi2uNEdsaoS/IKUQlrB8Xv0ia5TnRqbJFAkaaHC/8s8Yuw+iqGVFXHPYAVOtJig5e9DM+qQjpSg1irS+snBAdVGaTTOd1hVgbbIH66dafD6kUxHkkqmPHg4fbuSPjIxXnfbONw2SeUOM2WTZaPkvf1wrcPQnmrNqeEX6zRJzrbleYLLYQJM5yNyJadHCe9HwID5LwBtRH3mjsFJaciFO7KT8sx75GnLZWJZhCNnQA61Ff4XytQkW6wsQbBIhaSqSYUoYrG8rpERzSP4cnPgcHaXcSxT7uXZ3Ls08Q574XAeO7Gj/S3mUOWzE3vkwikTz+fkM1lN93JHXz6K7d7veZWKSh3okRFonEoo7tke7OysKVqoGnyMm1hrdxwSn083xTdfSiAkGtIKPGhlHJkFXuTrA9luMt1Fz0X6zHjjUdHZkN6zx6+RCyDaCOlSnHWPc0bIO402d++wgZiq9bf4cK00yv6OX70f1qvYBYkzvxzg+S8z4onlVwkAAA==\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var actionContainer = 'xukbync';
export var backLinksMenu = 'xukbyng';
export var backlinksList = 'xukbyn7';
export var collapsedIcon = 'xukbynf';
export var fontSize = 'var(--xukbyn1)';
export var pageInfoDimmed = 'xukbyn5';
export var propertyActionButton = 'xukbynd';
export var propertyConfigButton = 'xukbyne';
export var propertyRootHideEmpty = 'xukbynh';
export var root = 'xukbyn2';
export var tableBodyRoot = 'xukbyna';
export var tableBodySortable = 'xukbynb';
export var tableHeader = 'xukbyn3';
export var tableHeaderBacklinksHint = 'xukbyn6';
export var tableHeaderCollapseButtonWrapper = 'xukbyn4';
export var tableHeaderDivider = 'xukbyn9';
export var tableHeaderTimestamp = 'xukbyn8';