import "../../core/src/components/page-list/collections/collection-list-item.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/page-list/collections/collection-list-item.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA8VW227bMAx971cIBQo0wBQ413Yqtv8YhmFQJNrmqkiGJDfphv77YMmO5cRpl6LA3iKGIg8Pj0hPfy6ru50yGflzRYhEVyn+zEiuYP9wRYgwylhGnri9pZTnOWqgHvaeVha33D7T4DBpXEvAovSMrJZVuNqEoK60qB8ZyWIw7TlqRpy3KHxj2qH0JSOzLLtpjlxhoSl62LrgBV6Ujd1brh16NJqRDRePhTW1ljE5yaZz94mYigv0z+HUXDFPYHNldoyUKCXoAKC2rilHQs5r1QJQioqS6wJYF+Ph6uVq2vHCyiZSYOc48xEvwfFASBLiu+SeU6FQPPKNgi/e1vAjROwAVQa1B5vemgWHjtTlKpK6MVaCpZZLrF3DWzSf9G1ApIAYPK3gPHaao1IgJzFd00IuGxoH/lvQdftPcNzyPW17uVi3oLaoO9s82vrq5qG6ynRNtaC4xyeIsWyBmirIPSP0PgbriOiUYmqvUAMj2uhwq+JSoi7aa/cxXWReWl4UCfMHEGwDubEQW2G0B+0Zub5+GEDjG2dU7UMSbypGVhFB0GRu7JbFn4p7+HZLV9lNYCTiyBKRL4eVtMdU2vGvM4J+Q32V4gJKo2SiwRO9zGPONvQBXvIEIoRP6VN4i8SgnZTKQ6+O083OhkuMqIvjFCFqD3q6emecMZCLFmSn1DHIh5dMvhKWo3WeihKVjCIeCG+2Hgo9XFF89IaNCO6HNxbBp3tMy8seeMGrbir0EZfjs/1MiNO5nRvtqcPfcCS5YC/pYjDtVuPJQlSJFkSUujCq3uoTGDG559Ynb5qRrOV1MGgEV+K2mQeEkvWy2k+6PKFrhOxK9EBdxUUYEzvLq8ZcO7DUgQLhu+nRo19HqY3sjldJcNvJwWfXCmudZa+gSHCGfdqnBKWwcuh6bhyoPFmHPdq7s2jP7m0Hwmg53NyvlrZ3/8jr+8u4v0yfb6txvH4URqdVn+i8GwFHgzqee7ifPw5uS+5F3UoexdFu7Bbv4KOqx83/L+6RudJYGvmkMjour8e/uQj/r9p5zBsA7V4P2UDLMSx9EvFxSeKIo2Ekr8e/iV/+AvigV1N/CwAA\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "../../core/src/components/page-list/docs/page-list-item.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/page-list/docs/page-list-item.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA71W227bMAx9z1cIBQo0wBQ412Uqtv8YhmFQJNrmKkuGJDfphv77YMmO7cRJ167Ym02L5OHhEenZj3Uqodok5PeEEImuVPyJkVTB4X5CiDDKWEYeub2jlKcpaqAeDp6WFgtun2g4MK2P5oBZ7hlZr8rgWoegLreoHxhJassepc8ZmSfJbf3KFWaaoofCMeK8BS/ymFN7jjrYUPja5C3XDj0azciOi4fMmkrLmJwks4X7QEzJBfqn8Fa7mEewqTJ7RnKUEnSIXFlXlyMh5ZXy95Pnyaytn+W1R2DhNMNJ/eHgsfBeiG+Se06FQvHAdwo+e1vB9xCxTVwa1B5s32seDrTkrdaRvJ2xEiy1XGLlGNlG61l7BhQKiLH7BVyGTlNUCuQ0Zqs7xWXN1uB8AbpqvoSDBT/QpovLTRJBFahb2yLauuIWobjStL2zoLjHR4ixbIaaKkg9I7SpsOWh1YipvEINjGijg1fJpUSdNW7bmC4SLy3Psh7xRxBsB6mxEDthtAftGbm5uR9A4ztnVOVDEm9KRtYRQZBeamzB4qPiHr7e0XVyGxiJOPryXg0raV77Co6fLuj2BfGVigvIjZI9CZ7JZRFzNqEDvJdJCtroU3XsxWm4+cVwPSPq7DRFiNqBmq3fGGcM5LIB2SpxDPLxopIvhKVonaciRyWjSAfCmm+GQg4uio962IhgO/RYjg/UCzf2Z+U8pvU4beTZfcp4Wd+HYfTVq6Kfj+LUaE8d/oITeQV7TpeDwbYeTxaiSrQgoqyFUVWhz2DE5J5b37u/jCQNx4OhIrgSd/XdJ5RsVuVh2uYJHSRkn6MH6kouwkjYW17W5sqBpQ4UCN9Oig79JspuZB1cJcEV0+OZfSOyTZJcQdHDGVZklxKUwtKh67hxoNLe0uvQfryI9uIqdiCMlsNlfLW0g/tLXt9exvZ1+nxZjeP1ozC6X/WJzjs8n94PT8Peq9rRU/3Jomu36ODfqMPN/x/u8z+6kbFRW2p19FVyXtzV+7j7t8EYIICW1/st3i9JHGs0jOHN+K/t8x9c9mjQRgsAAA==\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "../../core/src/components/page-list/tags/tag-list-item.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/page-list/tags/tag-list-item.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA8VWbW/bIBD+nl+BKlVqpBE5r8uItv8xTdNE4GyzErAAN+mm/fcJsBPs2M1aVdq3+HLcPffcwx2zH/NSLU7HOkO/JwhxYStJnwnKJZx2E4SYltoQ9ETNA8Y0z4UC7ODkcGXEgZpnHBym3rUEUZSOoPWqCkd9CGxLI9QjQZm3HAV3JUHzLLuPsZWjQhFknRHMeROVolBYODjYYAbHSm93hiornNCKoD1lj4XRteIxOcpmC/sB6Yoy4Z7Dlz+in8DkUh8JKgXnoELG2lhfDoec1tJFTFJiVlJVAGlj7CZ/JrMzMaT0oQI9/dQ9YoLjmZE0xjdOHcVMCvZI9xI+O1PD9xCyhVRpoRyYzrF58Gh5Xa0jr3ttOBhsKBe19WxG81XrOlwyiNHTGsbR41xICXwa0/kuUu6Z7PgfQNXNP8HxQE+46fBy04A6CNXaFtGWlLcI5VW6bawBSZ14ghjMFEJhCbkjCG9jtJaJVkC6dlIoIEhpFU5VlHOhiubYNuaL3HNDiyLh/oKC7CHXBmI3tHKgHEF3d7sONrq3WtYuZHG6ImgdIQRh5tocSPwpqYOvD3id3QdOIpBU/KtuKc1nqu/414iqbyiwkpRBqSVPdHilmEXM2YQ+w0vuQYTwIb0PN1kM8km5PHern28+Gi8xClVc5QhhL7Bn67cGGoK5bGC2ch0CfbnQ6AsiuTDWYVYKyaOSO+qbb3pyD2ckHTxiIoZt78gydSJo/bqbXtCqHQ9JzNXwoB+JcT3Ec60ctuIX9KQX7CVediff+h+yxRyOGrdLq80aCjuThVHJHvz9RxhtVtVp2oIMHULoWAoH2FaUhbFwNLTy5tqCwRYkMNdOiwTkJgprYGG8WKw9TM8+x0ZFmyx7AUbYnJc8IKWorLAXRizIPKW/p49Vr5cfR4GP7m0LTCve3dwvVrmnFqbDUnifKpN6tq/T5m0lDhMhmFZp+b3CEkCf3g/QyY7iGW1Mchd6K7BdsJ0nVQKc/l/gA2PDW7xCUqX060sK2L+qgJ+1dSL3CJr9HdKB4jf6y94vC9OyPigchu5m+AWcJI7jv901vUdB+/1mXANXC9KEPTFtBx+WzQvnqoi/bZeoYjMMAAA=\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "../../core/src/components/page-list/list.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/page-list/list.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA6VU2W7bMBB891cQAQokQGlIaeMkDAL0P4qgWJMraxOJJKjV4RT+90KUD1mRix6P1AxnZ3dWXHruoHhNxc+FEC0ZzpVIk+TT00KIrMBOifRpsVssB9rtHK2ETk4+aWcZyGKQFkpUYridnEO89agE2YIsyoresYfXoN82wdXWKNFAuJYSsqwnnADpA5UQtlK7woWbkb0v0Z6hyhewVdH/oQ9pKKBmclYJ7Yq6tD0SXCs34JVIV74bCd3NC0FBGyuJsayU0GgZw1G/ygPZNyVik67BkBWuVSInY9COpO/Ppa2zOEIf/qrwa10xZf0gLKPlgS/RmguuPGjibTydKn43wCAzaFwgxpfPYvnjLjNYrxKV932IA7FHvvr7tnBzSJrb266tp1Ds51h4vEuPEYoZ9luQYyDuXTJ2LA1qF2CIazKiR9VQRYxm9v6IBpqpwd+zIMLeVTTUgnXliprjKgba5Lwf3WS/cxywVeJjPpM4hXiXZM3074Fh1FXu2mcONb7E4v+y8f+xIidI16HqpwI1u5HL9XhiZ4ZIO/vxp1vvE//TXjAEFy7L7I/6oovp/W/HF+XwzIjr0ZP0kCS+u4lqe+nVEENOBuO8nq/6MK6GNGay3C12vwB7ZpvdJgUAAA==\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var clearLinkStyle = 'ptxalj9';
export var colWrapper = 'ptxalj5';
export var deleteButton = 'ptxaljb';
export var deleteIcon = 'ptxaljc';
export var editTagWrapper = 'ptxalja';
export var favoriteCell = 'ptxalj8';
export var groupsContainer = 'ptxalj3';
export var heading = 'ptxalj4';
export var hidden = 'ptxalj7';
export var hideInSmallContainer = 'ptxalj6';
export var listRootContainer = 'ptxalj0';
export var pageListScrollContainer = 'ptxalj1';
export var root = 'ptxalj2';