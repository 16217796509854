import "../../core/src/components/attachment-viewer/styles.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/attachment-viewer/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA71WbYvjNhD+vr9CXCkkEAXZm2x3tVC4fujfKLI1cdRVJCMpm2TL/feiFydybOc2x3EQcDwa6xnNPPOMlv8UT1avjoSg/x4QarUVTmhFkQHJnHiH1weEuLCtZCeKNhKO3uCfmAsDdXSutdzvlF85CO62FBWE/O5ftyCarevevz0sO7giwA02/ndvndiccK2VA+Uosi2rAVfgDgAq33FdtuGLlnEuVOMh2iN6jsaK1W+N0XvFKXpnZoYx22yEAvxeYslOYPDFA7dG7Jg5zcPBtHLYig+gqEgAwXRIqCtCvK3WUpvh1g6ODluoteLdhpU2HAx2usUpNWS5TkFelqw7SaDIain41dIEVDyFUFZw+Ct6x48CagjkYFhLkdL+6Y36HcxG6gNFW8G5z2ZWjzLU46ZLgf5EV/6D+jUesssck6JRWDjYWYpqUA5MVjEsYeMuzp3VJMIEcx7g8kPrHVXazZZ2qw/zfgBKK+j5P44HODxhytZlAaQUrRU20HkrHOBAQopaE7rhoA1Pya0MsDfsDT3sVcDutUK2ug6r0y00kbZBa1yWQtZXVwl7ijAZodcjhF5HQktPq661ylV0vMXyvGl2zDRCebaO1O2PQRhjfTUaBvl+GA6MEzGODPN5vPbTKY+0TXj5ccrnq+O83JDJDywUhyNFZFI0KSqmWGgg5mfA5BdawUYbCMjn6n/58toLhFVWy70LgYTQQxCpm0gUFef0Lr3E5iO9sHFxhRuarTEidOY8DyOTz5sClfnlupijnAHy/XXLauFOXi3LdV/RE408O84UyVbFjjVAA5OYwY1hXIBys+vwqr1zWvn1qgKDOWzYXro5KtrjAjnDlG2ZAeW8Yb4YbOd0TO1icO67Nu5lgo2z9rNyMJiU58lIhl1ZBawdO3ZTqWaynnmpQhitSAjtM2M06GMadEdfmQBYdQMpn3OdHPaG38Tg+6GhFyLYMu7bypNjlQgSXuJWWf5xJVn9hktCFsg0FZuRBUq/ZUHm86k29TnrFMr/T/kf7fh7lTwrUH2vhI0rwXRVunJ0LXS5R5U+ZZl4PHZNlvTj8ayTl0zk9Hk60ycC+p7ZW3q+m/0qOtwvUffcBH90Yk3IIP851++8Jt0d/Oalji9rLSVrLfDvXKh4HAdn93mSkDAsO25cj0v4/C3sii2rX8mWPOKlBQm1S/no7/lb+fL18e+vrw/f/gfYPAgqOA0AAA==\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var error = '_16so4x05';
export var errorBtns = '_16so4x08';
export var errorMessage = '_16so4x07';
export var errorTitle = '_16so4x06';
export var pdfIndicator = '_16so4x0a';
export var pdfPage = '_16so4x0b';
export var pdfThumbnail = '_16so4x0e';
export var pdfThumbnails = '_16so4x0c';
export var pdfThumbnailsList = '_16so4x0d';
export var titlebar = '_16so4x01';
export var titlebarChild = '_16so4x02';
export var titlebarName = '_16so4x03';
export var viewer = '_16so4x09';
export var viewerContainer = '_16so4x00';
export var virtuoso = '_16so4x04';