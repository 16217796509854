import "../../core/src/components/tags/styles.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/tags/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA7VW247aMBB95yvclSpBt14lbPZmtP2OStU+GGeSuHVsy3YgabX/XtkhF0igy0MfADH3OWdmkruGF2XEoh8pdRRDqV3z6kwFb+jPAiGmhDIE7ahZYkyzjEvAWlAGhRIpGBz0q83ifXHXxomDW8qtFrQhKBNQbxYo/OKUG2COK0l83KqUXrPnqSsIiqPos/+bU01QvNb1KOY6xAyadXSkuZ/PFkwf9ZB5b7zEf09S9rGSEEvTNOUyJyjyllpZ3hZsQFDHd+ClTmmCGBVsie/vHnSNbg8AGZryGmulNRhMJSuUwQXwvHAr9AXheOW9BWSOoNZzVE2Idz5MsFqhWxQ/6jrEUTswmVB7ggqepiBHrTxcgCU5C0vfehzp+sABQlvKfuVGVTLFc8OwW2MudeXwYBeqK7k8tE5QckrnUZlbZfwk+aYrS67IK2gDZpQXa8NLaprxOD6GFKHZ3His4mmngemSmpxLgvAgagsjSCoZaFeVE1zCIMiUdNjy30BQ3MHqRRktuWgI4rIAw91m2KORZNqfM1RaTQ1IN+6AkNHCnV3K3Ro7qN14Occ4PF27lmFWntumhp3wku7TolYPLEdRKxwGk1ZOzRH/NLNq41AtwL3f83zxPyvreNZgpqQD6QiymjLAW3B7gNAEFTyXmDsoLUEMpAMz7eeEySNUg7y2q95mf6jwIYo2Z5gINFhgSqbUNNML2aPQtnUxtS3Hni/BoYMX18Pmn7tUY1DXHT8DtdOaXv7bSh7yRkcEJNMxOuGefmxwjdpfRXiX7T45xQQhVhnrm9OKdwFOAEmOwKPtozNTrLKQjh6e/8Cr8ExemI+29X69knnGTrLf+PQ3bzOuHz8523bONGXcNYGy9wXhdtllJKHyr10F6xWad4zHMdsyraMOXpUG+Xbels0c7V6ZHt+OcK4/ssVhla7a2NMpmWKfXmIoC8qAcKZMeQBbUAffl0l4hvem+bXXeXavz97dvMW+fa2wzCghMDVA8Y7DXivj3tA3lPLdcRWObgWgT7z0FjQMyF86usxvLAoAAA==\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var inlineTagsContainer = 'yihm0c3';
export var menuItemList = 'yihm0cg';
export var menuItemListScrollable = 'yihm0ce';
export var menuItemListScrollbar = 'yihm0cf';
export var searchInput = 'yihm0c6';
export var spacer = 'yihm0cc';
export var tagEditIcon = 'yihm0cb';
export var tagSelectorEmpty = 'yihm0cd';
export var tagSelectorItem = 'yihm0ca';
export var tagSelectorTagsScrollContainer = 'yihm0c9';
export var tagsEditorRoot = 'yihm0c1';
export var tagsEditorRootMobile = 'yihm0c2 yihm0c1';
export var tagsEditorSelectedTags = 'yihm0c5';
export var tagsEditorTagsSelector = 'yihm0c7';
export var tagsEditorTagsSelectorHeader = 'yihm0c8';
export var tagsInlineEditor = 'yihm0c0';
export var tagsMenu = 'yihm0c4';