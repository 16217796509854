import "../../component/src/ui/dnd/drop-indicator.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../component/src/ui/dnd/drop-indicator.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA71VwW7bMAy95yuIAAOSdUqddGk7FfuSYQfZphOhimTITJd02L8PluxasuM0C4rdbInieyQfycUm29oE7+D3BIAx/5dweCwPTxOA0lSSpNEcRFoZtSesT8mUHJL6y8rNlppvhQVxeBF21vpZzuvz1BCZXWNUGqkJLcMX1FRx0Ebj0+TPZNHw4DzFwlh0fDKjCTVxmE7rt7msSiWOHFJlsudxeq9M6hwPHFYe/sAq+Sr1hkNqbI6WpcYF90vmtI0ZJ47xFn1Ywxsfo4skFdnzxpq9zjmQFboqhUVNHtLBZEYZG3tZzYP7Bn/lU90cWpHLfcVhnXwKTis6KuRQGSXzOF2iILQfkq1lP6ohcR9+JlQ2i3MDt7CKUtcE1emj4/wjFySYNozQ7qQW6vuU7B6nP6Pav9HvKeRrZNUp0ZWgMHbXVEMJwpljypLFGj73ijn/Amcu5zFgl2OHx5Z1cG8G64hRpPYrSb3LaR1yahF7tO7d5X9q4FaorvBepuO6byV+F/F9cHx7+hkV3K33eEJfD1E5AgdsOUxq8O7R2cc96fI0jh4kpnMz1Ocl6KIzD42+RTaXu4YbWJaH8HHqHtnhYIsg0giisb4cJIsEOYqSneyYy2HyoLSjIPk/6GAIgf2FeO8Vcd1UHW2awbQVRSE1stLKnbBHv0IiXucWZCPeJBzEyfkdeJp928y9LIV9PUL09B7r6F83/P8CELmehKYIAAA=\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var bottom = 'gchr0ec';
export var edgeLine = 'gchr0ee';
export var horizontal = 'gchr0e7';
export var horizontalIndent = 'var(--gchr0e1)';
export var indicatorColor = 'var(--gchr0e2)';
export var left = 'gchr0ed';
export var lineAboveStyles = 'gchr0e4';
export var lineBelowStyles = 'gchr0e5';
export var localLineOffset = 'var(--gchr0e9)';
export var outlineStyles = 'gchr0e6';
export var right = 'gchr0eb';
export var terminalSize = 'var(--gchr0e0)';
export var top = 'gchr0ea';
export var treeLine = 'gchr0e3';
export var vertical = 'gchr0e8';