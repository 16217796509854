import "../../component/src/components/auth-components/share.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../component/src/components/auth-components/share.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA61X246jOBB9z1eUZtVS94MRkMv00NJq/mRlcEE8MTaynUvPKP++whgCBBJ6tU8Jhavq1Kmb+XnAz1zTEg38E1XCXspiDX9WAOGL+wGwmkqTK10moJWlFl9DhsXbxwrgugLYPjgYvQ+ORuGDs+td7+x1FbRoQgiEKpTTy5W0xPDfmMCJ6ldCaJ5zicTJ92TttN3DGXmxtwnswrCWZUooPVJKBc0OTqOkuuCSaK9SXWrhCbXlGRWECl7IBErOmMCPIbS/oUpyro0lKif2s8IlMLezMD2QVFmrygQ2DRJR6+79yfi9ETJuKkE/E8gFOoGDSbjF0iSQobSoJ8AK+jWsm1msE6h63iJnvKKMcVl0Aa3DBnylDLdcyQQ0Cmr5achrFJy53aujJXsu7aSlOLxzKGiKwh3uuEmFyg5z+bd4scRgpiSj+pO4I2/zWXhIlCkX8hSPYMfPk+Bt3zijqVHiaLGWjkAO/QAIzG0CS3AEqLXSDs0UWe5tx9FA8Uy15LKYVfXvp5Q3TunMmd0nEG2HMbTPU8VSB64ZaqIp40eT1FOolqoT6lyocwJ7zhhKd5Jmh0Kro2Rkcgw0hqbQJQnNLbakSIvSJvDt20cPczzCHI8x95PVy4afNe6/VZX/12azNwwSoEerpuP4K8/z+s1vwiXDSwLxHDPDqFLMlcYHYcXhqJz88zMqxREf1GoTvs9UG6p/7EKIHCPtdiBKc0dCret13LCTvKSNh9vWioKtAS5zLrlFV+90OAK3z5stpaYJYdAu88vGzwqXwvV4Jm2BznaF4PIwVXS7yXG3WTY4vw/HX7safh2N5Xk94nyyTUUzJCnaMzY98mx5vC8cDMtGmZu8fq22zkaMR8H2y33UlVTD1bB/esH8cMG0ntbxzEK9Y+0GdZKuJ8XwAwKNBl3rMLxttqmdsYTE4aVlvH/pIMYoDE/7/tgKw9P5fwp6STsNgAVnTavKj9Vl15g+8JcnMO9XwNC7V3HeS3oh3vJ7NE4YhcByKxZck9yxpZe6u6sShcAc05ur5+e/PlXSZ+XwMlkN9S9hXGPWNGGmxLGU/6VOeliy/tJf3/WJ57v2bPaay4Pvbq+w++4VfpbIOAWTaUQJVDJ4LbkkXUjxprq8OVtz2e+obuZJHPohC9AztO1Jh58JndxhLXRdclFPMkDvZSk13HjRdSaIW03OBPHnZm8yN936cJmtLt36nkhbg9RSbduPtLlOfezxi4bHaehC3t3oHhXn/YVoYJR5W10NSyVxGc3bmp8Ry5k3NwJxXV3/Bd5FMNMxDwAA\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var authCodeContainer = '_1pltxmg6';
export var authCodeErrorMessage = '_1pltxmg8';
export var authCodeWrapper = '_1pltxmg7';
export var authContent = '_1pltxmg5';
export var authInputWrapper = '_1pltxmg1';
export var authPageContainer = '_1pltxmga';
export var formHint = '_1pltxmg2';
export var hideInSmallScreen = '_1pltxmgd';
export var illustration = '_1pltxmge';
export var input = '_1pltxmgc';
export var loading = '_1pltxmg4';
export var modalHeaderWrapper = '_1pltxmg0';
export var resendButtonWrapper = '_1pltxmg9';
export var signInPageContainer = '_1pltxmgb';