import "../../core/src/modules/app-sidebar/views/open-in-app-card/open-in-app-card.css.ts.vanilla.css!=!../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/modules/app-sidebar/views/open-in-app-card/open-in-app-card.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA51UwW7bMAy95yuEAQPSgwrbc7NMxS77i2EYBtWiHS6yZEh04nTovw+W7NR2k7bbKZFMvvf4SPH2V7p/2FTbNmF/Vow9yGJfOdsaJdhBujXnsizRAD9kXMsTOP4cwRuHtXSnm/s+0ToFjjupsPWCbZvu+VawtOmYtxrVFVA0HhV8ixgxKaAWrfPWCaaglK2m/qqRSqGpBEuzpmNpMhJ13O+ksseF7njJ0wCn0DdangQrNYS0SjZnrY31SGiNYCV2oCIqka0FSzcxRENJghVSF+u7RB2OjLMsS5ougB9R0U6wPB8kPXI0CrqFnuGWN7axh6FKctKM3OF/aV3NktvMM5AeOBpuW7pfPa1uz936oSRJvkOlwHz9QK6FDz9DB88AA5aWBN/XWZJ8vJkhpCH62c2kdzOL0l/41P9yhQ6KqLKwuq1N/8XZIw8uBoum+MJYWgstPXFbcjo1cBNHbOzy4O1/jcaEKAuol1ubx3p+t56wPPHCGgJDMYiDUTOgTwFoB1jtSLBsMx3h5WCffUvOppXWEPf4CIuOh/vOz0Xnc9FodAgdtEuNleFIUHvBCjAE7k0GX8cXY3X/YJZeEnQ0e68h5zjUepckM3V3Qd3bBb2frpauQsPJjk2ZsG0uN/CKCVOk7QLp8z8hTSfkpR3vrH9YUI3FCDtRsw1qhrWQDVvhPF7DOUyi3zk0e8GWtGPQFZexsGbi8oT6y4w6X1Dn76Fedkle9vb6Zrj86DxJR2OiYOmM4+Eyx6tIk/RiWnW+nVc9npdVT/JVHPsLwiB8eW3YNZp9XOb9SUFhnYyOtEaB65/3/erpLz8tyRhuBwAA\"}!../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var appIcon = '_1kb6g8uc';
export var appIconCol = '_1kb6g8ub';
export var button = '_1kb6g8u3';
export var buttonGroup = '_1kb6g8u2';
export var closeButton = '_1kb6g8u9';
export var contentCol = '_1kb6g8ua';
export var controlsRow = '_1kb6g8u6';
export var link = '_1kb6g8ue';
export var pane = '_1kb6g8u1';
export var rememberCheckbox = '_1kb6g8u8';
export var rememberLabel = '_1kb6g8u7';
export var root = '_1kb6g8u0';
export var spacer = '_1kb6g8ud';
export var subtitleRow = '_1kb6g8u5';
export var titleRow = '_1kb6g8u4';