import "../../core/src/components/blocksuite/block-suite-editor/styles.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/blocksuite/block-suite-editor/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA7VTzY7aMBC+8xRzqRakNUooUNZ0pb5HVVVDPEmmdWzLGSDbinevEgglKVttDz0lGn/z/czY868ph1WsJIGfEwB/oJhbf1SNhsxy2E4ADNfB4ouG3FLTFtqvMhwpE/ZOQ+btvnLbyWky79nSjq0kLkrRkCbJu75RQ/pPpAABjWFXqJ0X8VXHFpqB2uLvajfI9x3yKr6zPvs+BOgS6ynmOTtSyCqgI6uObAqSzwYFVS0o9PxQkKOIwq54+DLrWMc+nzZjn28mz9lxXZL5D9QUo49v513eHRjAkY2Uv2ddYSzYaUgA9+JvtyY+aFh1tH/q9fVsH2sfNQg1cr0MRfTH8f5WnZ3cO1E1/yANB4xTpS65u3qplrNtDzpe7sQ6SW70W5/Ls/LOR0NRRTS8r3VfPcdRlnK51myr0N+x8ZjWna/M2zbFwFMbSdWUeWcwvqgOMhv0fni1dxfRmXstm67lQFE4Q6vQcuE0VGyMpe1wQOk8paqtSURX5z5W+vxrUWi6CM0jqDQ0Q/6n4db7Nzpa+rd9LZy3mZyQEw0ZOaE4YML7TK+/9gKDhk24p1dhoy6lwZjIsPh4PpqN1nwPWLMhdcE8wmJ5Sf+pzYHsKMKB6Rh8FJh2pPDxGdbLJDTnVzMKN9BL12frp8npFw+edclbBQAA\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var affineDocViewport = '_1ip5rmt1';
export var affineEdgelessDocViewport = '_1ip5rmt2';
export var docContainer = '_1ip5rmt3';
export var docEditorGap = '_1ip5rmt4';
export var docEditorRoot = '_1ip5rmt0';
export var docPropertiesTable = '_1ip5rmta';
export var docPropertiesTableContainer = '_1ip5rmt9';
export var pageReferenceIcon = '_1ip5rmt8';
export var titleDayTag = '_1ip5rmt6 _1ip5rmt5';
export var titleTodayTag = '_1ip5rmt7 _1ip5rmt5';