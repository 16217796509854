import "../../core/src/modules/app-sidebar/views/index.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/modules/app-sidebar/views/index.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA7VV227bMAx9z1cQAQa0wBQ4bZMWKgoM+4K9D3tQLNrhKkuCpNw25N8HSbZjJ83QYthTHFLUIQ8PqRlVBrevBfyeAFghJemarUwIpuHwZPfPk+Nk1p75LkUQbC08Wxkn0b0Et8EfKTIbmKN6HTgUs4XdgzeKJGyFu2FMVBVpZNs7psQBHSPtSeLXHJWDby+hyLNKGRFI1y/TCDZt0UT5Wjuz0ZKVRhnHr6AMzllHjXCHIcj8P4IArDFzUQpV3syL4hMwWBZ2n5yNcDVpFozlsLiLLPc2hVXg8JBtK7Nnfi2k2Z2hW2PNFl3rvH0etEBI2ngOy1HvrtfaO1BhGZzRIxK6KmIB54mzhw9BlIpQh046/8h0LF6JwzehUb2vp39JgvMVVsZhSqY0OqAOHKbTWLA1ngIZzUGsvFGbgNFK2mPUefw2VpQUDmdJa0MeWev7DEVu0akAhxZF4JB/z5yefiGHRdGK4OSgRtTYIWWIk3fIw12qZUcyrDnctwrrmtn9rxRGeTnSr6mWPvo+RUvyVokDT+fieaGo1owCNp5DiTqge06MqU2jWS1sd3V/0UNeKz2HDpUItE0ctsl1yjpX2gV6ylaSwzLflWEHYIsEFo9xKKAAsQlmeHM3aO2W41DYPcyX2XgB93PjA1UH1uvBW1EiW2HYIeqrdPTZLAfZzGF+kc1wL8TMbj9UNIAzu0z5eAofE2xwQnectxqE2XzhR3otsr4pJs5wizp4DtpoHOu+oj3KaEpDn4Lyjkqfbty17uUoxsJ9x0Q3RorRKD/miTUW9cWuenNh94XN3yqs4797ouL3L0ZaxgbdD3Cf0mWj5qYuoJaDDdgV2ur9S4OSBFhHOqT42fBdHTS243cAzlK+x1PQ47Wg4+T4BwhDqaewBwAA\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var floatingMaxWidth = 768;
export var hoverNavWrapperStyle = 'ifoevk1';
export var navBodyStyle = 'ifoevk6';
export var navHeaderButton = 'ifoevk2';
export var navHeaderNavigationButtons = 'ifoevk3';
export var navHeaderStyle = 'ifoevk5';
export var navStyle = 'ifoevk4';
export var navWrapperStyle = 'ifoevk0';
export var resizeHandleShortcutStyle = 'ifoevk8';
export var sidebarFloatMaskStyle = 'ifoevk7';