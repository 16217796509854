import "../../component/src/components/affine-other-page-layout/index.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../component/src/components/affine-other-page-layout/index.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA81UwW7bMAy95yuIAgOSgwI7TbrOuexPBtqiHTW2JEhM4m7Ivw+W7cXykgK99RSHeqQe36O4/pXuUv/2libwZwFwIFUdOIM0Sb7tFwAXJflw+yuVtzW+Z1DW1HaB7ldI5ahgZXQGhalPjQ4nRrPw6jdlcEa3FALLUmkSIZ6jp1WHssarPtNRjazO1EVzLI6VMyctRWFq42YlJsfWqQbdew9bzXL72wtzJrdfXBfrsdU0tDpUVvpATnEE2AQAG5tB0tWsqeTh0/X6JHfVuHVTqpZkF8JaVVoopsZnUJDmjgvA28mzKjvemklzBt5iQSInvhAF/SxKqXSVQfpiW0g3iW0jjs+RX7uNbe/4dYeQuFB+VCzQWuGoCofSYRUV34bi//XXuysqtBlsZ3x2U1Eju5havmPUhwPimxvmMvaYBNVDOUmFcdi3po2mSLFtJ9jrjN9L4De5dLO1g2mqc0XQmTT7DPDEJs5c2xqV/lx/k/wfIfPmzPmyj1/a+RDRT+Ix/tTwGyfJCYdSnfxYKcRuMuWmFf6A0lzG2IQrfh0X041toR97gAZb8XC2o9VxRwF2qEfsTT5I1s8eCP1MgQxLJjfoMLzOp6f4Rsy9qU883MhsmvlKyGtTHCcvssC6WHbUQcA2se1qOgPJete3+dj1GoujSJPVjKs2vMxq9CxMKfjd0ioQb9BVSosJteviZ0NSIfjCEWlALWEZqdq9hz57tgfnu2j05DqFbgfoPwFGPyPU62PUfYJKf0jw+6N6j8y6Lq5/AWQ2ptLyBgAA\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var affineLogo = '_151sjj11';
export var draggableHeader = '_151sjj13';
export var hideInSmallScreen = '_151sjj18';
export var hideInWideScreen = '_151sjj17';
export var iconButton = '_151sjj16';
export var menu = '_151sjj19';
export var menuItem = '_151sjj1a';
export var root = '_151sjj10';
export var topNav = '_151sjj12';
export var topNavLink = '_151sjj15';
export var topNavLinks = '_151sjj14';