import "../../core/src/components/affine/affine-error-boundary/error-basic/error-detail.css.ts.vanilla.css!=!../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/affine/affine-error-boundary/error-basic/error-detail.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA7VU247bIBB991eglSrtPhBhx02zRP2Sqg/EjG26GCwYx8lW++8V+BInstKL1BdjZoaZcw4zbCC174Yx8jMhpLVeobKGE3H0VncIh4QQqXyrxYWTUsM5GMJKpXJQDMGF1V1jgudH51GVF1pYg2CQkwIMggsuoVVlqEJo/NJcg6pq5CRl7FPY90pifd2iE2bGpDVhm60nIDxQZajt8JB8JJuBQhop/AewMYNH4fAPAFei5WTfxtKtkFKZipN0NxgacaZjfM7YYFsynP0PeX6TAgX1te0DquLt6xO6Dp6+R/6LEruhxHwwiwF3eO0JXKltz0mtpIQoDMIZae8Ck/CNLK2T9OhAvHESFxosi+TbmHwSR3RoJ8KTja2xHZwrdEm4KOs4OQn3TKkoS2WAnjIawXkorJHCXV7iFVuD1Kt34CTNB1V1iJ4qZ9lgjHH9aMwZW8L/rahzsjtV83vZ7xpjO1300FMedHnttDnL55jl34k8lqt1qpnEaoSrlKFHi2gbTvIbMrv1GRrPoG2vvbzW99sR398+A3P9L8MzNM1N3p6nWVpKM1ZplJk1z24vZb/O43Hx13ho2aHxv7SuWW3QURUNJc4jP+EZRZofi90NvNeH3TZX5cRZFAjP6Z5JqF4OyccvDbHNMq8FAAA=\"}!../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var actionButton = 'e1ozn07';
export var actionContainer = 'e1ozn06';
export var actionContent = 'e1ozn08';
export var arrowIcon = 'e1ozn09';
export var errorContainer = 'e1ozn01';
export var errorLayout = 'e1ozn00';
export var illustration = 'e1ozn04';
export var label = 'e1ozn02';
export var scrollArea = 'e1ozn03';
export var text = 'e1ozn05';