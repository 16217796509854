import "../../core/src/components/page-list/page-group.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/page-list/page-group.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA6VU227bMAx9z1cIAQY0wBQ4l2WJhwL9j2EPskQ7bGTJkOQs2ZB/HyTZmZ3YKdo+WSapI/LwkC8HOOeGlWCJPexe9XpB/k4ISb6EDyG6YhzdOSXJj/C/Byz2LiVJdfKGy4SQRXIfvehHH5l5otQwgSfKtZSssphJoFwrB8rRGDeLiJfJy21Sy5GkPvPMcOJjZV4m85hKEm4ItJVk55TkEkKE/1KBBrhDrVLCtaxL5T0Fq1Ky8ThXjFXA0EcwudS/U7JHIUB1A34K5hi1jjl4nuoK1PRXuMMUliy+0LYrma8sAWaBoqK6dqMwXGoLYgxo+Qho/aGi172ivw1jMImFouigtCnhoBwYb66YEKiK0AGy2FSncNjEdrTdWW7jv+8vQ1+KM8idN2WMHwqjayVaWbA8RwX0v4NWBktmzl4q2gRV1BYMtSCBu5QoraCbf7r3HQtVjKKHkAhIc5QSxKwDsQmXfeVBulf792DntbHapKTSGFm4+rfxnlaOWvwDN29GezmLTEgP0fM7ODlqgWslutVe0XefRxdoWRy0G2w23PT+uI1I4LW2DvNzO71d1x1Vb+R/srOebpqxzrQRYMLCqG2j1670yLanYPZOBcwG1NPjpbvHrpFZ35XcFNfmPtQL5Fp11OwMUxbjaIZzrk1JkvlybMyzuC+a1QnieZozaaFZGVeIlBjtV8rTLhFQPKgy+0pGkZ2pW+BBGvi71sUDrdw0eRnpG+emQy6qPRjsMsQf1XFPEL1jqAFoBmaEh2S+CkKM6qZwBOXs3ULiH5DjPyZ3+tXvBwAA\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var collapsedIcon = 'sk9jo4b';
export var collapsedIconContainer = 'sk9jo4c';
export var collapsibleContent = 'sk9jo43';
export var collapsibleContentInner = 'sk9jo44';
export var header = 'sk9jo45';
export var headerCollapseIcon = 'sk9jo47';
export var headerCount = 'sk9jo49';
export var headerLabel = 'sk9jo48';
export var root = 'sk9jo40';
export var selectAllButton = 'sk9jo4a';
export var spacer = 'sk9jo46';