import "../../core/src/components/mobile/config-modal/styles.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/mobile/config-modal/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA51TyW6DMBC98xVzqdQeHAGlSep8TGXwQKw6tjWYBFL13yvIwpK2WU6WZnnLjGf2Mae0WexD+AoAnJBSmYJDuAoA7BYp13bHGg6i8raNpSL7LMhWRrLMaksctoKeGRN5rgyybcy0aJDYoK7EzBopqHlZBd/B7EgYjQmj2NXDdNyl/6DwWHvmSG0OoABSlU6LhoMyuq3JNdZtXGhVGKY8bkoOGRqP1IYL4Tgsx4SvHeEZ6GaE20X2wz11rlEVa88hScZakvFs4sTVEM0PPRcK25dJRZh5ZQ1v5VQbcxZ56Oux3373eQUlniic/7eetPLemoH3vm8xdjZBXXbZnZJ+zSEKw6fLIQHk1nhWqj1yiBZusKQSdd7vqEd9f8jxkW0kpscUV7+nR/LqYvUTw2kH09/KDdc0/FGpJYnESEhVlacbmrLdb31yGdnDl3EWsnT10foP2FvNnHAEAAA=\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var bottomDoneButton = '_6rby7z8';
export var bottomDoneButtonContainer = '_6rby7z7';
export var doneButton = '_6rby7z6 xn1iiob';
export var group = '_6rby7z9';
export var groupContent = '_6rby7zb';
export var groupTitle = '_6rby7za xn1iiom';
export var pageContent = '_6rby7z4';
export var pageModalContent = '_6rby7z0';
export var pageTitle = '_6rby7z2 xn1iiob';
export var popupContent = '_6rby7z5';
export var popupModalContent = '_6rby7z1';
export var popupTitle = '_6rby7z3 xn1iiob';
export var rowItem = '_6rby7zc';