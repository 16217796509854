import "../../core/src/components/page-list/collections/collection-list-item.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/page-list/collections/collection-list-item.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA8VW227bMAx971cIBQo0wBQ413Yqtv8YhmFQJNrmqkiGJDfphv77YMmO5cRpl6LA3iKGIg8Pj0hPfy6ru50yGflzRYhEVyn+zEiuYP9wRYgwylhGnri9pZTnOWqgHvaeVha33D7T4DBpXEvAovSMrJZVuNqEoK60qB8ZyWIw7TlqRpy3KHxj2qH0JSOzLLtpjlxhoSl62LrgBV6Ujd1brh16NJqRDRePhTW1ljE5yaZz94mYigv0z+HUXDFPYHNldoyUKCXoAKC2rilHQs5r1QJQioqS6wJYF+Ph6uVq2vHCyiZSYOc48xEvwfFASBLiu+SeU6FQPPKNgi/e1vAjROwAVQa1B5vemgWHjtTlKpK6MVaCpZZLrF3DWzSf9G1ApIAYPK3gPHaao1IgJzFd00IuGxoH/lvQdftPcNzyPW17uVi3oLaoO9s82vrq5qG6ynRNtaC4xyeIsWyBmirIPSP0PgbriOiUYmqvUAMj2uhwq+JSoi7aa/cxXWReWl4UCfMHEGwDubEQW2G0B+0Zub5+GEDjG2dU7UMSbypGVhFB0GRu7JbFn4p7+HZLV9lNYCTiyBKRL4eVtMdU2vGvM4J+Q32V4gJKo2SiwRO9zGPONvQBXvIEIoRP6VN4i8SgnZTKQ6+O083OhkuMqIvjFCFqD3q6emecMZCLFmSn1DHIh5dMvhKWo3WeihKVjCIeCG+2Hgo9XFF89IaNCO6HNxbBp3tMy8seeMGrbir0EZfjs/1MiNO5nRvtqcPfcCS5YC/pYjDtVuPJQlSJFkSUujCq3uoTGDG559Ynb5qRrOV1MGgEV+K2mQeEkvWy2k+6PKFrhOxK9EBdxUUYEzvLq8ZcO7DUgQLhu+nRo19HqY3sjldJcNvJwWfXCmudZa+gSHCGfdqnBKWwcuh6bhyoPFmHPdq7s2jP7m0Hwmg53NyvlrZ3/8jr+8u4v0yfb6txvH4URqdVn+i8GwFHgzqee7ifPw5uS+5F3UoexdFu7Bbv4KOqx83/L+6RudJYGvmkMjour8e/uQj/r9p5zBsA7V4P2UDLMSx9EvFxSeKIo2Ekr8e/iV/+AvigV1N/CwAA\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var actionsCellWrapper = '_4p7wlob';
export var dateCell = '_4p7wloa';
export var dndCell = '_4p7wlo2';
export var dragPageItemOverlay = '_4p7wlo1';
export var iconCell = '_4p7wlo8';
export var operationsCell = '_4p7wloc';
export var root = '_4p7wlo0';
export var selectionCell = '_4p7wlo4';
export var tagsCell = '_4p7wlo9';
export var titleCell = '_4p7wlo5';
export var titleCellMain = '_4p7wlo6';
export var titleCellPreview = '_4p7wlo7';
export var titleIconsWrapper = '_4p7wlo3';