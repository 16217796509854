import "../../core/src/components/page-list/tags/tag-list-item.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/page-list/tags/tag-list-item.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA8VWbW/bIBD+nl+BKlVqpBE5r8uItv8xTdNE4GyzErAAN+mm/fcJsBPs2M1aVdq3+HLcPffcwx2zH/NSLU7HOkO/JwhxYStJnwnKJZx2E4SYltoQ9ETNA8Y0z4UC7ODkcGXEgZpnHBym3rUEUZSOoPWqCkd9CGxLI9QjQZm3HAV3JUHzLLuPsZWjQhFknRHMeROVolBYODjYYAbHSm93hiornNCKoD1lj4XRteIxOcpmC/sB6Yoy4Z7Dlz+in8DkUh8JKgXnoELG2lhfDoec1tJFTFJiVlJVAGlj7CZ/JrMzMaT0oQI9/dQ9YoLjmZE0xjdOHcVMCvZI9xI+O1PD9xCyhVRpoRyYzrF58Gh5Xa0jr3ttOBhsKBe19WxG81XrOlwyiNHTGsbR41xICXwa0/kuUu6Z7PgfQNXNP8HxQE+46fBy04A6CNXaFtGWlLcI5VW6bawBSZ14ghjMFEJhCbkjCG9jtJaJVkC6dlIoIEhpFU5VlHOhiubYNuaL3HNDiyLh/oKC7CHXBmI3tHKgHEF3d7sONrq3WtYuZHG6ImgdIQRh5tocSPwpqYOvD3id3QdOIpBU/KtuKc1nqu/414iqbyiwkpRBqSVPdHilmEXM2YQ+w0vuQYTwIb0PN1kM8km5PHern28+Gi8xClVc5QhhL7Bn67cGGoK5bGC2ch0CfbnQ6AsiuTDWYVYKyaOSO+qbb3pyD2ckHTxiIoZt78gydSJo/bqbXtCqHQ9JzNXwoB+JcT3Ec60ctuIX9KQX7CVediff+h+yxRyOGrdLq80aCjuThVHJHvz9RxhtVtVp2oIMHULoWAoH2FaUhbFwNLTy5tqCwRYkMNdOiwTkJgprYGG8WKw9TM8+x0ZFmyx7AUbYnJc8IKWorLAXRizIPKW/p49Vr5cfR4GP7m0LTCve3dwvVrmnFqbDUnifKpN6tq/T5m0lDhMhmFZp+b3CEkCf3g/QyY7iGW1Mchd6K7BdsJ0nVQKc/l/gA2PDW7xCUqX060sK2L+qgJ+1dSL3CJr9HdKB4jf6y94vC9OyPigchu5m+AWcJI7jv901vUdB+/1mXANXC9KEPTFtBx+WzQvnqoi/bZeoYjMMAAA=\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var actionsCellWrapper = '_1hn2xwub';
export var dateCell = '_1hn2xwua';
export var dndCell = '_1hn2xwu2';
export var dragPageItemOverlay = '_1hn2xwu1';
export var iconCell = '_1hn2xwu8';
export var operationsCell = '_1hn2xwuc';
export var root = '_1hn2xwu0';
export var selectionCell = '_1hn2xwu4';
export var tagIndicator = '_1hn2xwue';
export var tagIndicatorWrapper = '_1hn2xwud';
export var tagsCell = '_1hn2xwu9';
export var titleCell = '_1hn2xwu5';
export var titleCellMain = '_1hn2xwu6';
export var titleCellPreview = '_1hn2xwu7';
export var titleIconsWrapper = '_1hn2xwu3';